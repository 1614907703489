import React from "react";
// Customizable Area Start

import {
  Container,
  Box,
  styled
} from "@material-ui/core";
import { Typography, Button, Input } from "@builder/component-library"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import UpdateDetailsPopup from "./components/UpdateDetailsPopup.web";
// Customizable Area End


import UpdateAddressController, {
  Props,
  configJSON,
} from "./UpdateAddressController.web";

export default class UpdateAddress extends UpdateAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Container>
          <Typography component="h5" style={webStyle.mainTitle}>
            {configJSON.updateOrderTitle}
          </Typography>
          <Box style={webStyle.checkoutTableContainer}>
            <Box>
              <Input
                label={configJSON.address}
                value={this.state.address}
                onChangeText={(event) => this.handelInputChange(event, "address")}
                data-test-id="address"
                placeholder={configJSON.addressPlaceholder}
              />
            </Box>
            <Box>
              <Input
                label={configJSON.address2}
                value={this.state.address2}
                onChangeText={(event) => this.handelInputChange(event, "address2")}
                data-test-id="address2"
                placeholder={configJSON.addressPlaceholder}
              />
            </Box>
            <Box>
              <Input
                label={configJSON.city}
                value={this.state.city}
                onChangeText={(event) => this.handelInputChange(event, "city")}
                data-test-id="city"
              />
            </Box>
            <Box>
              <Input
                label={configJSON.state}
                value={this.state.stateName}
                onChangeText={(event) => this.handelInputChange(event, "stateName")}
                data-test-id="stateName"
              />
            </Box>
            <Box>
              <Input
                label={configJSON.country}
                value={this.state.country}
                onChangeText={(event) => this.handelInputChange(event, "country")}
                data-test-id="country"
              />
            </Box>
            <Box>
              <Input
                label={configJSON.pinCode}
                value={this.state.pinCode}
                onChangeText={(event) => this.handelInputChange(event, "pinCode")}
                data-test-id="pinCode"
                placeholder={configJSON.pincodePlaceholder}
              />
            </Box>
            <MainBoxInputContainer>
              <Typography style={{ fontWeight: "bold" }}>Address type</Typography>
              <Box sx={{ marginTop: 2, position: 'relative' }}>
                <Box
                  data-test-id="regionTestId"
                  onClick={this.handleDropdownAddress}
                  className='selectInput'
                >
                  <Typography data-test-id="regionValueTestId" children={this.state.addressType ? this.state.addressType : 'Select Region'} />
                  {this.state.isOpenAddressType ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
                {this.state.isOpenAddressType && (
                  <Box
                    className='selectOption'
                  >
                    {this.state.displayAddressType.map((region, index) => (
                      <Box
                        data-test-id={`regionSelectTestId${index}`}
                        key={region.key}
                        onClick={() => this.handleInputSelect(region)}
                        className='options'
                      >
                        <Typography
                          data-test-id={`regionOptionsTestId${index}`}
                        >{region.name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </MainBoxInputContainer>
            <Box style={webStyle.buttonsContainer}>
              <Box sx={webStyle.errorTypography}>
                {this.state.address.length <= 3 ||
                  this.state.address2.length <= 3 ? (
                  <Typography
                    style={webStyle.errorTypography}
                  >
                    {configJSON.addressPlaceholder}
                  </Typography>
                ) : null}
              </Box>
              <Button
                disabled={
                  this.state.address.length <= 3 ||
                  this.state.address2.length <= 3
                }
                data-test-id="doneButton"
                onPress={this.onAddingNewAddress}
                style={webStyle.doneButtonStyle}
                textStyle={{ color: "green" }}
                text={configJSON.done}
              >
              </Button>
              <Button
                data-test-id="cancelButton"
                onPress={this.navigateToOrderList}
                style={webStyle.cancelButtonStyle}
                textStyle={{ color: "red" }}
                text={configJSON.buttonCancel}
              >
              </Button>
            </Box>
          </Box>
        </Container>
        <UpdateDetailsPopup
          data-test-id={"updateDetailsPopup"}
          isUpdatePopupOpen={this.state.isUpdatePopupOpen}
          handleUpdatePopupClose={this.handleUpdatePopupClose}
          handelInputChange={this.handelInputChange}
          length={this.state.length}
          breadth={this.state.breadth}
          height={this.state.height}
          weight={this.state.weight}
          validateOrderUpdate={this.validateOrderUpdate}
        />
      </>

      // Customizable Area End
    );
  }

}
// Customizable Area Start
const MainBoxInputContainer = styled(Box)({
  "& .selectOption": {
    position: 'absolute',
    border: '1px solid #ccc',
    width: '200px',
    backgroundColor: '#fff',
    zIndex: 1,
  },
  "& .options": {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#f0f0f0' },
  },
  "& .selectInput": {
    border: '1px solid #ccc',
    padding: '10px',
    cursor: 'pointer',
    width: '200px',
    display: "flex",
    justifyContent: "space-between",
  },
})
const webStyle = {
  updateOrderHeading: {
    textAlign: "center",
    fontSize: "20px",
  },
  mainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    textAlign: "center" as "center",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  checkoutTableContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "22px",
    height: "fit-content"
  },
  doneButtonStyle: {
    backgroundColor: "#fff",
    border: "1px solid rgb(98, 0, 238)",
    width: "120px",
    marginRight: "10px",
  },
  cancelButtonStyle: {
    backgroundColor: "#fff",
    border: "1px solid rgb(98, 0, 238)",
    color: "red",
    width: "120px",
  },
  textField: {
    width: "-moz-available",
  },
  errorTypography: {
    width: "-moz-available",
    marginBottom: "5px",
    height: "50px",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
  },
};
// Customizable Area End


