interface Order {
  id?: number;
  channel_id?: number;
  channel_name?: string;
  base_channel_code?: string;
  channel_order_id?: string;
  customer_name: string;
  customer_email?: string;
  customer_phone?: string;
  customer_address?: string;
  customer_address_2?: string;
  customer_city?: string;
  customer_state?: string;
  customer_pincode?: string;
  customer_country?: string;
  customer_alternate_phone?: string;
  pickup_location?: string;
  pickup_city_id?: number;
  delivery_city_id?: number;
  package_instructions?: string | null;
  order_type?: string | null;
  payment_status?: string;
  total?: string;
  tax?: string;
  sla?: string;
  shipping_method?: string;
  expedited?: string | null;
  status: string;
  status_code?: number;
  payment_method?: string;
  is_international?: number;
  purpose_of_shipment?: number;
  channel_created_at?: string;
  created_at?: string;
  updated_at?: string;
  shipments: Shipment[];
  activities: string[];
  allow_return?: number;
  is_incomplete?: number;
  errors?: string[];
  pickup_exception_reason?: string;
  pii_removed?: number;
  others?: {
    weight?: string;
    document?: number;
    isd_code?: string | null;
    quantity?: number;
    buyer_psid?: string | null;
    dimensions?: string;
    billing_city?: string;
    billing_name?: string;
    company_name?: string | null;
    billing_email?: string;
    billing_phone?: string;
    billing_state?: string;
    currency_code?: string | null;
    package_count?: number;
    reseller_name?: string | null;
    customer_gstin?: string | null;
    billing_address?: string;
    billing_city_id?: number | null;
    billing_country?: string;
    billing_pincode?: string;
    billing_isd_code?: string | null;
    eway_bill_number?: string;
    shipment_purpose?: string;
    shipping_charges?: string;
    billing_address_2?: string | null;
    billing_country_id?: number | null;
    billing_alternate_phone?: string | null;
  };
  products:Product[];
  order_tags?: string[];
  categories?: string[];
  pickup_address: {
    id?: number;
    name?: string;
    address?: string;
    address_2?: string;
    city?: string;
    state?: string;
    address_type?: string | null;
    pin_code?: string;
    pickup_code?: string;
    phone_verified?: number;
  };
  bulk_status?: number;
  service_type?: string | null;
}

interface OrderItem {
  item:{
    channel_id?: number;
  channel_name?: string;
  base_channel_code?: string;
  id?: number;
  customer_address?: string;
  customer_address_2?: string;
  customer_city?: string;
  customer_state?: string;
  channel_order_id?: string;
  customer_name?: string;
  customer_email?: string;
  customer_phone?: string;
  customer_country?: string;
  customer_alternate_phone?: string;
  customer_pincode?: string;
  order_type?: string | null;
  payment_status?: string;
  total?: string;
  tax?: string;
  pickup_location?: string;
  pickup_city_id?: number;
  delivery_city_id?: number;
  package_instructions?: string | null;
  status: string;
  status_code?: number;
  payment_method?: string;
  is_international?: number;
  sla?: string;
  shipping_method?: string;
  expedited?: string | null;
  updated_at?: string;
  shipments: Shipment[];
  activities: string[];
  purpose_of_shipment?: number;
  channel_created_at?: string;
  created_at?: string;
  
  allow_return?: number;
  is_incomplete?: number;
  errors?: string[];
  pickup_exception_reason?: string;
  
  others?: {
    weight?: string;
    document?: number;
    isd_code?: string | null;
    quantity?: number;
    billing_email?: string;
    billing_phone?: string;
    billing_state?: string;
    buyer_psid?: string | null;
    dimensions?: string;
    billing_city?: string;
    customer_gstin?: string | null;
    billing_address?: string;
    billing_city_id?: number | null;
    billing_name?: string;
    company_name?: string | null;
   
    currency_code?: string | null;
    package_count?: number;
    reseller_name?: string | null;
    
    billing_country?: string;
    billing_pincode?: string;
    billing_isd_code?: string | null;
    eway_bill_number?: string;
    shipment_purpose?: string;
    shipping_charges?: string;
    billing_address_2?: string | null;
    billing_country_id?: number | null;
    billing_alternate_phone?: string | null;
  };
  pii_removed?: number;
  order_tags?: string[];
  products:Product[];
  
  categories?: string[];
  pickup_address: {
    id?: number;
    
    state?: string;
    address_type?: string | null;
    pin_code?: string;
    name?: string;
    address?: string;
    address_2?: string;
    city?: string;
    pickup_code?: string;
    phone_verified?: number;
  };
  service_type?: string | null;
  bulk_status?: number;
  
}
}

interface Product {
  id: number;
  channel_order_product_id: string;
  name: string;
  channel_sku: string;
  quantity: number;
  product_id: number;
  available: number;
  status: string;
  price: string;
  product_cost: string;
  hsn: string;
}

interface ProductItem {
  item:{
  id?: number;
  quantity?: number;
  product_id?: number;
  channel_order_product_id?: string;
  name?: string;
  channel_sku?: string;
  status?: string;
  price?: string;
  available?: number;
  product_cost?: string;
  hsn?: string;
  }
}

interface Shipment {
  id: number;
  isd_code?: string;
  courier?: string;
  courier_id?: number;
  shipping_charges?: string;
  weight?: string;
  dimensions?: string;
  length?: number;
  breadth?: number;
  height?: number;
  shipped_date?: string | null;
  pickup_scheduled_date?: string;
  pickup_token_number?: string | null;
  awb: string;
  return_awb?: string;
  volumetric_weight?: number;
  pod?: string | null;
  etd?: string;
  saral_etd?: string;
  rto_delivered_date?: string | null;
  delivered_date?: string | null;
  etd_escalation_btn?: boolean;
  rto_initiated_date?: string | null;
  package_images?: string;
  pickup_estimated_date?: string;
  is_own_key?: number;
  hyper_local?: number;
}
interface  PickupAddress{
  id?: number;
  name?: string;
  address?: string;
  address_2?: string;
  city?: string;
  state?: string;
  address_type?: string | null;
  pin_code?: string;
  pickup_code?: string;
  phone_verified?: number;
}

interface ShiprocketTrackOrderTypeWeb {
  data: TrackingData[];
}
interface TrackingData {
  tracking_data: {
    track_status: number;
    shipment_status: number;
    shipment_track: ShipmentTrack[];
    shipment_track_activities: ShipmentTrackActivity[];
    track_url: string;
    etd: string;
    qc_response: QcResponse;
  };
}


interface TrackOrderType {
  data: TrackingData[];
}


interface QcResponse {
  qc_image: string;
  qc_failed_reason: string;
}
interface CourierItems {
  item:{
  base_courier_id: number;
  ship_type: number;
  courier_id: number;
  courier_name: string;
  is_rto_address_available: boolean;
  courier_type: string;
  uid: null | string;
  freight_charge: number;
  rto_charges: number;
  shipping_rate_per_slab: number;
  zone: string;
  rate: number;
  is_custom_rate: number;
  cod_multiplier: number;
  cod_charges: number;
  increment_weight: number;
  is_surface: boolean;
  shipping_charge_additional_weight: number;
  rto_shipping_rate_per_slab: number;
  base_weight: number;
  
  rating: {
    rto: number;
    pickup: number;
    ndr: number;
    
    delivery: number;
  };
  rto_performance: null;
  pickup_performance: null;
  delivery_performance: null;
  cod: number;
  average_rating: string;
 
  description: string;
  mode: number;
  blocked: number;
  suppression_dates: {
    delivery_supress_date_to: string;
    pickup_supress_date_from: string;
    pickup_supress_date_to: string;
    
    delivery_supress_date_from: string;
  };
  pickup_availability: number;
  seconds_left_for_pickup: number;
  min_weight: number;
  weight_cases: null;
  estimated_delivery_days: string;
  realtime_tracking: string;
  tracking_performance: null;
  overall_rating: string;
  cost: string;
  edd: number;
  edd_time: string;
  pickup_priority: string;
  delivery_boy_contact: string;
  pod_available: string;
  call_before_delivery: string;
  rank: string;
  
  is_own_key: number;
}
}

interface Courier {
  base_courier_id: number;
  courier_type: string;
  ship_type: number;
  courier_id: number;
  courier_name: string;
  is_rto_address_available: boolean;
  zone: string;
  rate: number;
  is_custom_rate: number;
  cod_multiplier: number;
  cod_charges: number;
  uid: null | string;
  freight_charge: number;
  rto_charges: number;
  shipping_rate_per_slab: number;
  shipping_charge_additional_weight: number;
  rto_shipping_rate_per_slab: number;
  base_weight: number;
  increment_weight: number;
  is_surface: boolean;
  rating: {
    ndr: number;
    rto: number;
    pickup: number;
    delivery: number;
  };
  average_rating: string;
  rto_performance: null;
  pickup_performance: null;
  delivery_performance: null;
  cod: number;
  description: string;
  mode: number;
  blocked: number;
  suppression_dates: {
    pickup_supress_date_to: string;
    delivery_supress_date_to: string;
    pickup_supress_date_from: string;
    delivery_supress_date_from: string;
  };
  min_weight: number;
  pickup_availability: number;
  seconds_left_for_pickup: number;
  tracking_performance: null;
  overall_rating: string;
  weight_cases: null;
  estimated_delivery_days: string;
  realtime_tracking: string;
  delivery_boy_contact: string;
  pod_available: string;
  call_before_delivery: string;
  rank: string;
  cost: string;
  edd: number;
  edd_time: string;
  pickup_priority: string;
  is_own_key: number;
}

const initializeCourier: Courier = {
  base_courier_id: 0,
  courier_type: '',
  ship_type: 0,
  courier_id: 0,
  courier_name: '',
  is_rto_address_available: false,
  zone: '',
  rate: 0,
  is_custom_rate: 0,
  cod_multiplier: 0,
  cod_charges: 0,
  uid: null,
  freight_charge: 0,
  rto_charges: 0,
  shipping_rate_per_slab: 0,
  shipping_charge_additional_weight: 0,
  rto_shipping_rate_per_slab: 0,
  base_weight: 0,
  increment_weight: 0,
  is_surface: false,
  rating: {
    ndr: 0,
    rto: 0,
    pickup: 0,
    delivery: 0,
  },
  average_rating: '0.0',
  rto_performance: null,
  pickup_performance: null,
  delivery_performance: null,
  cod: 0,
  description: '',
  mode: 0,
  blocked: 0,
  suppression_dates: {
    pickup_supress_date_to: '',
    delivery_supress_date_to: '',
    pickup_supress_date_from: '',
    delivery_supress_date_from: '',
  },
  min_weight: 0,
  pickup_availability: 0,
  seconds_left_for_pickup: 0,
  tracking_performance: null,
  overall_rating: '0.0',
  weight_cases: null,
  estimated_delivery_days: 'Not Available',
  realtime_tracking: '',
  delivery_boy_contact: 'Not Available',
  pod_available: 'Not Available',
  call_before_delivery: 'Not Available',
  rank: '',
  cost: '',
  edd: 0,
  edd_time: '',
  pickup_priority: '',
  is_own_key: 0,
};



const initializeOrder = {
  id: undefined,
  channel_id: undefined,
  channel_name: '',
  base_channel_code: '',
  channel_order_id: '',
  customer_name: '',
  customer_email: '',
  customer_phone: '',
  customer_address: '',
  customer_address_2: '',
  customer_city: '',
  customer_state: '',
  customer_pincode: '',
  customer_country: '',
  customer_alternate_phone: '',
  pickup_location: '',
  pickup_city_id: undefined,
  delivery_city_id: undefined,
  package_instructions: null,
  order_type: null,
  payment_status: '',
  total: '',
  tax: '',
  sla: '',
  shipping_method: '',
  expedited: null,
  status: '',
  status_code: undefined,
  payment_method: '',
  is_international: undefined,
  purpose_of_shipment: undefined,
  channel_created_at: '',
  created_at: '',
  updated_at: '',
  shipments: [],
  activities: [],
  allow_return: undefined,
  is_incomplete: undefined,
  errors: [],
  pickup_exception_reason: '',
  pii_removed: undefined,
  others: {
    weight: '',
    document: undefined,
    isd_code: null,
    quantity: undefined,
    buyer_psid: null,
    dimensions: '',
    billing_city: '',
    billing_name: '',
    company_name: null,
    billing_email: '',
    billing_phone: '',
    billing_state: '',
    currency_code: null,
    package_count: undefined,
    reseller_name: null,
    customer_gstin: null,
    billing_address: '',
    billing_city_id: undefined,
    billing_country: '',
    billing_pincode: '',
    billing_isd_code: null,
    eway_bill_number: '',
    shipment_purpose: '',
    shipping_charges: '',
    billing_address_2: null,
    billing_country_id: undefined,
    billing_alternate_phone: null,
  },
  products: [],
  order_tags: [],
  categories: [],
  pickup_address: {
    id: undefined,
    name: '',
    address: '',
    address_2: '',
    city: '',
    state: '',
    address_type: null,
    pin_code: '',
    pickup_code: '',
    phone_verified: undefined,
  },
  bulk_status: undefined,
  service_type: null,
}

const initialPickupAddress: PickupAddress = {
  id: undefined,
  name: '',
  address: '',
  address_2: '',
  city: '',
  state: '',
  address_type: null,
  pin_code: '',
  pickup_code: '',
  phone_verified: undefined,
};
const initialShipment: Shipment = {
  id: 0,
  isd_code: '',
  courier: '',
  courier_id: 0,
  shipping_charges: '',
  weight: '',
  dimensions: '',
  length: 0,
  breadth: 0,
  height: 0,
  shipped_date: null,
  pickup_scheduled_date: '',
  pickup_token_number: null,
  awb: '',
  return_awb: '',
  volumetric_weight: 0,
  pod: null,
  etd: '',
  saral_etd: '',
  rto_delivered_date: null,
  delivered_date: null,
  etd_escalation_btn: false,
  rto_initiated_date: null,
  package_images: '',
  pickup_estimated_date: '',
  is_own_key: 0,
  hyper_local: 0,
};
interface ShipmentTrack {
  id: number;
  awb_code: string;
  courier_company_id: number;
  shipment_id: number;
  order_id: number;
  pickup_date: string | null;
  delivered_date: string | null;
  weight: string;
  packages: number;
  current_status: string;
  delivered_to: string;
  destination: string;
  consignee_name: string;
  origin: string;
}

interface ShipmentTrackActivity {
  date: string;
  status: number;
  activity: string;
  location: string;
  activity_ar: string;
  location_ar: string;
}

interface ShipmentTrackActivityItem {
  item:{
  status: number;
  activity: string;
  location: string;
  date: string;
  activity_ar: string;
  location_ar: string;
  }
}

interface ShipmentTrackingInfo {
  track_status: number;
  shipment_status: number;
  shipment_track: ShipmentTrack[];
  shipment_track_activities: ShipmentTrackActivity[];
  track_url: string;
  status: boolean;
  error: null | string;
  message: string;
  status_code: number;
}






const defaultShipmentTrack: ShipmentTrack = {
  id: 0,
  awb_code: '',
  courier_company_id: 0,
  shipment_id: 0,
  order_id: 0,
  pickup_date: null,
  delivered_date: null,
  weight: '0',
  packages: 0,
  current_status: '',
  delivered_to: '',
  destination: '',
  consignee_name: '',
  origin: '',
};

const defaultShipmentTrackActivity: ShipmentTrackActivity = {
  date: '',
  status: 0,
  activity: '',
  location: '',
  activity_ar: '',
  location_ar: '',
};

const defaultShipmentTrackingInfo: ShipmentTrackingInfo = {
  track_status: 0,
  shipment_status: 0,
  shipment_track: [defaultShipmentTrack],
  shipment_track_activities: [defaultShipmentTrackActivity],
  track_url: '',
  status: false,
  error: null,
  message: '',
  status_code: 0,
};

export {
  Order,
  OrderItem,
  ShiprocketTrackOrderTypeWeb,
  Product,
  ProductItem,
  Shipment,
  initializeOrder,
  PickupAddress,
  initialPickupAddress,
  initialShipment,
  initializeCourier,
  Courier,
  CourierItems,
  ShipmentTrackingInfo,
  defaultShipmentTrackingInfo,
  ShipmentTrackActivityItem,
};
