import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { IUpdateHistory } from "./types";
export const configJSON = require("./config");
import storage from "framework/src/StorageProvider";
type ShipmentTrack = {
  id: number;
  awb_code: string;
  courier_company_id: number | null;
  shipment_id: number;
  order_id: number;
  pickup_date: string;
  delivered_date: string;
  weight: string;
  packages: number;
  current_status: string;
  delivered_to: string;
  destination: string;
  consignee_name: string;
  origin: string;
  courier_agent_details: string | null;
  courier_name: string;
  edd: string | null;
  pod: string;
  pod_status: string;
  rto_delivered_date: string;
};

type TrackingData = {
  track_status: number;
  shipment_status: number;
  shipment_track: ShipmentTrack[];
  shipment_track_activities:{ activity: string; date: string; location: string }[];
  track_url: string;
  qc_response: string;
  is_return: boolean;
  error: string;
};

type ShipmentData = {
  tracking_data: TrackingData;
};

type TrackingResponse = {
  data: {
    [key: string]: ShipmentData;
  };
  status: number;
};


const trackingResponse: ShipmentData = {
  tracking_data: {
    track_status: 0,
    shipment_status: 0,
    shipment_track: [
      {
        id: 0,
        awb_code: "",
        courier_company_id: null,
        shipment_id: 0,
        order_id: 0,
        pickup_date: "",
        delivered_date: "",
        weight: "",
        packages: 0,
        current_status: "",
        delivered_to: "",
        destination: "",
        consignee_name: "",
        origin: "",
        courier_agent_details: null,
        courier_name: "",
        edd: null,
        pod: "",
        pod_status: "",
        rto_delivered_date: ""
      }
    ],
    shipment_track_activities:  [{
      activity: "",
      date: "",
      location: ""
    }],
    track_url: "",
    qc_response: "",
    is_return: false,
    error: "Aahh! There is no activities found in our DB. Please have some patience it will be updated soon."
  }
};



// Customizable Area End


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: IUpdateHistory;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderId: number;
  shiprocketOrderId: string;
  trackingData: ShipmentData;
  listData : Object;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class TrackOrderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiOrderTrackingById: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderId: 0,
      shiprocketOrderId: "",
      trackingData: trackingResponse,
      listData: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestSendCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse || responseJson.error || responseJson.errors) {
        (errorResponse || responseJson.errors) &&
          this.showAlert("", responseJson.errors);
        responseJson.error &&
          this.parseApiCatchErrorResponse(responseJson.error);
      } else if (apiRequestSendCallId === this.apiOrderTrackingById) {
        this.trackORderSuccess(responseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const orderId = await storage.get("customerDataId");
    const shiprocketOrderId = await storage.get("shiprocketOrderId");
    this.setState({ orderId, shiprocketOrderId }, () => {
      this.trackOrderByIdMethod();
    });
  }

  trackORderSuccess = (responseJson: TrackingResponse) => {
    if (responseJson.data[this.state.shiprocketOrderId].tracking_data.error) {
      this.showAlert("Message", responseJson.data[this.state.shiprocketOrderId].tracking_data.error);
    } 
    if(responseJson.data[this.state.shiprocketOrderId].tracking_data.shipment_track_activities.length > 0) {
      this.setState({ trackingData: responseJson.data[this.state.shiprocketOrderId] });
    }
  }

  trackOrderByIdMethod = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData("token"),
    };
    const requestMessageData = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiOrderTrackingById = requestMessageData.messageId;
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.trackOrderEndPoint}${this.state.orderId}`,
    );
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessageData.id, requestMessageData);
  };

  handleGoBackToOrders = () => {
    this.props.navigation.navigate("ShiprocketIntegrate");
  };

  // Customizable Area End
}


