import React from "react";
// Customizable Area Start
import CustomerDetailPopup from "./components/CustomerDetailPopup.web";
import ShipmentDetailsPopup from "./components/ShipmentDetailsPopup.web";
import { OrderDataAttributesOrderItemWeb } from "./types";
import { Typography } from "@builder/component-library"
import { Box, Container } from "@material-ui/core";
// Customizable Area End

import ShiprocketIntegrateController, {
  Props,
} from "./ShiprocketIntegrateController.web";

export default class ShiprocketIntegrate extends ShiprocketIntegrateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Container>
          <Typography variant="3xl" style={shipWebStyle.shipMainWebTitle}>
            Orders Table
          </Typography>
          <Box>
            <Box sx={tableStyle.container}>
              <Box sx={tableStyle.header}>Order number</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Customer Name</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Email</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Phone Number</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Order Date</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Status</Box>

              {this.state.tableData.data.map((item, index) => (
                <Box
                  key={index}
                  data-test-id="tableRowId"
                  sx={tableStyle.row}
                  onClick={() => this.handleCustomerData(item)}
                >
                  <Box sx={tableStyle.cell}>{item.id}</Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.attributes.customer.data.attributes.first_name + item.attributes.customer.data.attributes.last_name}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.attributes.customer.data.attributes.email}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.attributes.customer.data.attributes.full_phone_number}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.attributes.customer.data.attributes.created_at}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.attributes.status}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
        <CustomerDetailPopup
          data-test-id={"customerDataPop"}
          sendShipRocket={(orderId: string) => this.apiShipRocket(orderId)}
          open={this.state.setOpen}
          data={this.state.customerData}
          handleClose={this.closeCell}
          handleNavigate={this.handleNavigateToUpdate}
          handleOrderStatusDetails={this.handleOrderStatusDetails}
          shipmentDetalsFetch={(orderId: string) =>
            this.apiDetalsFetch(orderId)
          }
          isLoadingGetShipRocketDetails={
            this.state.isLoadingGetShipRocketDetails
          }
          isLoadingSendShipRocket={this.state.isLoadingSendShipRocket}
        />
        {this.state.shipRocketModalData !== null && (
          <ShipmentDetailsPopup
            data-test-id={"shipmentDetailsPop"}
            shipRocketModalData={this.state.shipRocketModalData}
            data={this.state.customerData}
            showShipmentDataModal={this.state.showShipmentDataModal}
            handleClose={this.handleShipmentClose}
            handleInvoice={(idInvoice: string) => this.apiInvoice(idInvoice)}
            generateAwb={(orderId: string) => this.apiAwb(orderId)}
            downloadLabel={(orderId: string) => this.apiLabel(orderId)}
            awbError={this.state.awbError}
            labelDownloadError={this.state.labelDownloadError}
            pickUpRequestError={this.state.pickUpRequestError}
            pickupRequest={(orderId: string) => this.apiRequest(orderId)}
            downloadManifest={(orderId: string) =>
              this.apiManifest(orderId)
            }
            manifestError={this.state.manifestError}
            OrderCancel={(orderId: string) => this.cancelCell(orderId)}
            viewOrderDetails={(
              orderData: OrderDataAttributesOrderItemWeb[],
              status: string,
              orderId: string,
            ) => this.detailsCell(orderData, status, orderId)}
            isLoadingCancelOrder={this.state.isLoadingCancelOrder}
            isLoadingInvoice={this.state.isLoadingInvoice}
            isLoadingAwb={this.state.isLoadingAwb}
          />
        )}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const tableStyle = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: 2,
    alignItems: 'center',
    padding: 2,
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  header: {
    fontWeight: 'bold',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderBottom: '2px solid #ddd',
  },
  cell: {
    cursor: 'pointer',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  cellRight: {
    textAlign: 'right',
  },
  mainTitle: {
    color: 'rgb(98, 0, 238)',
  },
  row: {
    display: 'contents',
    cursor: 'pointer',
  },
};
const shipWebStyle = {
  tableHeadDiv: {
    borderCollapse: "collapse" as "collapse",
    width: "100%",
  },
  containerDiv: {
    padding: "12px"
  },
  shipMainWebTitle: {
    color: "#6200EE",
    textAlign: "center" as "center",
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
  },
  textStyleWeb: {
    color: "#000",
    fontSize: "16px",
  },
  trackMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  trackCardWeb: {
    padding: "10px",
    margin: "10px",
    border: "1px solid rgb(98, 0, 238)",
  },

  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
};
// Customizable Area End
