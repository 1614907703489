// Customizable Area Start
import React, { FunctionComponent } from "react";
import {
  Modal,
  Box,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "./CustomerDetailPopup.web";
import { Typography, Button, Input } from "@builder/component-library"

export type Props = {
  isReturnItemModalOpen: boolean;
  closeModal: () => void;
  updateInputValue: (event: string, name:string) => void;
  returnOrderlength: string;
  returnOrderbreadth: string;
  returnOrderheight: string;
  returnOrderweight: string;
  validateReturnOrder: () => void;
  isReturnLoading: boolean;
};

const ReturnOrderPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isReturnItemModalOpen}
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.isReturnItemModalOpen}>
        <Box className={classes.paper}>
          <Typography component="h5" style={returnOrderWebStyle.dimensionHeading}>
            Fill Details
          </Typography>
          <Box>
            <Typography style={returnOrderWebStyle.inputLable}>Length</Typography>
            <Input
              data-test-id={"returnOrderlengthTestId"}
              value={props.returnOrderlength || ""}
              onChangeText={(event) => props.updateInputValue(event, "returnOrderlength")}
            />
            <Typography style={returnOrderWebStyle.inputLable}>Breadth</Typography>
            <Input
              data-test-id={"returnOrderbreadthTestId"}
              value={props.returnOrderbreadth || ""}
              onChangeText={(event) => props.updateInputValue(event, "returnOrderbreadth")}
            />
            <Typography style={returnOrderWebStyle.inputLable}>Height</Typography>
            <Input
              data-test-id={"returnOrderheightTestId"}
              value={props.returnOrderheight || ""}
              onChangeText={(event) => props.updateInputValue(event, "returnOrderheight")}
            />
            <Typography style={returnOrderWebStyle.inputLable}>Weight</Typography>
            <Input
              data-test-id={"returnOrderweightTestId"}
              value={props.returnOrderweight || ""}
              onChangeText={(event) => props.updateInputValue(event, "returnOrderweight")}
            />
          </Box>
          <Button
            onPress={props.validateReturnOrder}
            style={returnOrderWebStyle.buttonWrapper}
            data-test-id="closeDimensionButton"
            text="Confirm Return"
            loading={props.isReturnLoading}
          >
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReturnOrderPopup;

const returnOrderWebStyle = {
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: 10,
    color: "#ffffff",
    width: "100%",
  },
  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },
};

// Customizable Area End
