import React from "react";
// Customizable Area Start
import Paper from "@material-ui/core/Paper";

import {
  Container,
  Box,
} from "@material-ui/core";
import { Typography, Button } from "@builder/component-library"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TrackOrderController, {
  Props,
  configJSON,
} from "./TrackOrderController.web";

export default class TrackOrder extends TrackOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Paper style={webStyle.paperContainer}>
            <Typography
              component="h5"
              style={webStyle.trackMainTitle}>
              {configJSON.trackOrderTitle}
            </Typography>
            <Box>
              {this.state.trackingData.tracking_data.shipment_track_activities.length > 0 && 
              this.state.trackingData.tracking_data.shipment_track_activities.map(
                (itemWeb, index) => {
                  return (
                    <Box key={`itemWeb${index}`} style={webStyle.trackCardWeb}>
                      <Typography style={webStyle.textStyleWeb}>
                        {itemWeb.activity}
                      </Typography>
                      <Typography style={webStyle.textStyleWeb}>
                        {configJSON.textDate} : {itemWeb.date}
                      </Typography>
                      <Typography style={webStyle.textStyleWeb}>
                        {configJSON.textLocation} : {itemWeb.location}
                      </Typography>
                    </Box>
                  );
                },
              )}
            </Box>
            <Box sx={webStyle.goBackButtonContainer}>
              <Button
                onPress={this.handleGoBackToOrders}
                data-test-id="goBackToOrdersButton"
                text={configJSON.textGoBackToOrders}
                >
              </Button>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  textStyleWeb: {
    color: "#000",
    fontSize: 16,
  },
  trackMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    textAlign: "center" as "center"
  },
  trackCardWeb: {
    padding: "10px",
    margin: "10px",
    border: "1px solid rgb(98, 0, 238)",
  },

  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  goBackButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
// Customizable Area End

