Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.getMethod = "GET";
  exports.getPublicKey = "GET";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  exports.createAccount = "bx_block_stripe_integration/payment_intents/create_connected_account?email="
  exports.accountOnboarding = "bx_block_stripe_integration/payment_intents/account_onboarding"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  // Customizable Area End
  