import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Linking, Platform } from "react-native";
import {
  Order,
  PickupAddress,
  Product,
  initialPickupAddress,
  initializeOrder,
  Shipment,
  initialShipment,
  Courier,
  initializeCourier,
  ShipmentTrackingInfo,
  defaultShipmentTrackingInfo,
} from "./types";
import React from "react";
import { Button } from "@builder/component-library";
const navigation = require("react-navigation");

interface TableDataRow {
  '0': string;
  '1': string;
  '2': string | JSX.Element;
  '3': string;
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  authCode: string;
  allOrderDetails: Order[];
  openOrdarModal: boolean;
  userOrderDetails: Order;
  trackOrderDetail: boolean;
  trackOrderDetailAgain: boolean;
  orderItemsDetails: boolean;
  SelectCourierModal: boolean;
  shipmentDetailsToggle: boolean;
  loading: boolean;
  access_token: string;
  shipmentDetailsData: Order;
  shipmentDetailsDataPickupAddress: PickupAddress;
  shipmentDetailsDataProduct: Product;
  shipmentDetailsDataShipment: Shipment;
  CourierData: Courier[];
  courierNewData: { key: string, name: string }[];
  orderTrackData: ShipmentTrackingInfo;
  orderShopCartId: string;
  isLoadingInvoice: boolean;
  isLoadingAwb: boolean;
  trackAwbLoading: boolean;
  courierId: string;
  courierName: string;
  trackAwbMsg: string;
  tableNewData: TableDataRow[];
  cilentId: string;
  cilentSecratId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShiprocketController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAuthtoken: string = "";
  getAccessToken: string = "";
  getAllOrders: string = "";
  getCancelOrder: string = "";
  getAwbApi: string = "";
  generateInvoiceApi: string = "";
  generatelabelApi: string = "";
  generatePickupRequestApi: string = "";
  generateMenifestApi: string = "";
  trackAwbsApi: string = "";
  postShipRocketApiCallId: string = "";
  getAllCourierApiCallId: string = "";
  getFetchApiDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      authCode: "",
      allOrderDetails: [],
      openOrdarModal: false,
      userOrderDetails: initializeOrder,
      trackOrderDetail: false,
      trackOrderDetailAgain: false,
      orderItemsDetails: false,
      SelectCourierModal: false,
      shipmentDetailsToggle: false,
      loading: false,
      access_token: "",
      shipmentDetailsData: initializeOrder,
      shipmentDetailsDataPickupAddress: initialPickupAddress,
      shipmentDetailsDataProduct: {
        id: 0,
        channel_order_product_id: "",
        name: "",
        channel_sku: "",
        quantity: 0,
        product_id: 0,
        available: 0,
        status: "",
        price: "",
        product_cost: "",
        hsn: ""
      },
      shipmentDetailsDataShipment: initialShipment,
      CourierData: new Array(initializeCourier),
      courierNewData: [],
      orderTrackData: defaultShipmentTrackingInfo,
      orderShopCartId: "",
      isLoadingInvoice: false,
      isLoadingAwb: false,
      trackAwbLoading: false,
      courierId: "",
      courierName: "",
      trackAwbMsg: "",
      tableNewData: [{
        0: "",
        1: "",
        2: "",
        3: ""
      }],
      cilentId : "",
      cilentSecratId : ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.checkResponse(message);
      } else if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getFetchApi()
  }

  openOrderDetails = (shipmentData: Order) => {
    if (shipmentData) {
      this.setState({
        openOrdarModal: !this.state.openOrdarModal,
        userOrderDetails: shipmentData,
      });
    }
  };
  orderDetailsTracking = (shipmentData: Order) => {
    this.setState({
      openOrdarModal: !this.state.openOrdarModal,
      shipmentDetailsData: shipmentData,
      shipmentDetailsDataPickupAddress: shipmentData.pickup_address,
      shipmentDetailsDataProduct: shipmentData.products[0],
      shipmentDetailsDataShipment: shipmentData.shipments[0],
    });

    setTimeout(() => {
      this.setState({
        trackOrderDetail: !this.state.trackOrderDetail,
      });
    }, 500);
  };

  viewOrderItemsDetails = (shipmentData: Order) => {
    this.setState({
      shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
      shipmentDetailsData: shipmentData,
    });

    setTimeout(() => {
      this.setState({
        orderItemsDetails: !this.state.orderItemsDetails,
      });
    }, 500);
  };

  viewShipMentDetails = (shipmentData: Order) => {
    this.setState({
      openOrdarModal: !this.state.openOrdarModal,
      shipmentDetailsData: shipmentData,
      shipmentDetailsDataPickupAddress: shipmentData.pickup_address,
      shipmentDetailsDataProduct: shipmentData.products[0],
      shipmentDetailsDataShipment: shipmentData.shipments[0],
    });

    setTimeout(() => {
      this.setState({
        shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
      });
    }, 500);
  };

  viewShipMentDetailsAgain = () => {
    this.setState({
      orderItemsDetails: !this.state.orderItemsDetails,
    });

    setTimeout(() => {
      this.setState({
        shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
      });
    }, 500);
  };

  selectCorierForAwb = () => {
    this.setState({
      shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
    });

    setTimeout(() => {
      this.setState({
        SelectCourierModal: !this.state.SelectCourierModal,
      });
    }, 500);
  };

  viewShipMentDetailsAgainAwb = () => {
    this.setState({
      SelectCourierModal: !this.state.SelectCourierModal,
    });

    setTimeout(() => {
      this.setState({
        shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
      });
    }, 500);
  };

  backToHomeScreen = () => {
    this.setState({
      trackOrderDetail: !this.state.trackOrderDetail,
      orderTrackData: defaultShipmentTrackingInfo,
      trackAwbMsg: "",
    });
  };

  backToHomeScreenTrack = () => {
    this.setState({ trackOrderDetailAgain: !this.state.trackOrderDetailAgain });
  };

  backToHomeScreenMain = () => {
    this.setState({ openOrdarModal: !this.state.openOrdarModal });
  };
  backToHomeScreenDetails = () => {
    this.setState({ shipmentDetailsToggle: !this.state.shipmentDetailsToggle });
  };

  generateAuthToken = () => {
    this.setState({ loading: true });
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAuthtoken = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateAuthToken +
      `?client_id=${this.state.cilentId}&response_type=code`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTokens = () => {
    let formdata = new FormData();
    formdata.append("grant_type", "authorization_code");
    formdata.append("client_id", String(this.state.cilentId));
    formdata.append("code", this.state.authCode);
    formdata.append(
      "client_secret",
      this.state.cilentSecratId
    );

    const header = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccessToken = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateAccessTokens
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllOrder = () => {
    this.setState({ loading: true });
    const header = {
      token: this.state.access_token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllOrders = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllOrder
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelOrder = (orderID: string) => {
    this.setState({ loading: true });
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("ids[]", orderID);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancelOrder = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.canelOrderApiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateAwb = (orderId: number | undefined, couriedId: string) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("order_id[]", `${orderId}`);
    formdata.append("courier_id", couriedId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAwbApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateAwb
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isLoadingAwb: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateInvoice = (orderId: string) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("ids[]", orderId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generateInvoiceApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateInvoice
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isLoadingInvoice: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generatelabel = (shipmentId: number) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("shipment_id[]", `${shipmentId}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generatelabelApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateLabelEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generatePickupRequest = (shipmentId: number) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("shipment_id[]", `${shipmentId}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generatePickupRequestApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generatePickupRequestEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateMenifest = (shipmentId: number) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("shipment_id[]", `${shipmentId}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generateMenifestApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateMenifestEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  trackAwb = (awbsId: string) => {
    const header = {
      token: this.state.access_token,
    };
    let formdata = new FormData();
    formdata.append("awbs[]", awbsId);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.trackAwbsApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.trackAwb
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ trackAwbLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkResponse = (message: Message) => {
    this.setState({ loading: false });
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if(apiRequestCallId === this.getFetchApiDataApiCallId){
      if(responseJson && !responseJson.errors){
        this.setState({cilentId : responseJson.client_id,cilentSecratId : responseJson.client_secret})
        this.generateAuthToken();
      }
    }
     else if (apiRequestCallId === this.getAuthtoken) {
      if (responseJson.status) {
        this.setState({ authCode: responseJson.data.code }, () => {
          this.getTokens();
        });
      }
    } else if (apiRequestCallId == this.getAccessToken) {
      if (responseJson.status === true) {
        let access_token = responseJson.data.access_token;
        this.setState(
          {
            access_token: access_token,
          },
          () => {
            this.getAllOrder();
          }
        );
      } else {
        this.setState({ loading: false });
      }
    } else {
      this.checkResponseNew(message);
    }
  };

  checkResponseNew = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    this.setState({ loading: false });
    if (apiRequestCallId === this.postShipRocketApiCallId) {
      if (responseJson?.data?.status) {
        this.setState({
          orderShopCartId: "",
        });
        responseJson.data.message &&
          this.showAlert("Message", responseJson.data.message);
        this.getAllOrder();
      } else {
        responseJson.error && this.showAlert("Message", responseJson.error);
      }
    } else if (apiRequestCallId === this.getAllOrders) {
      if (responseJson.data.status) {
        let dataValue = responseJson.data.data;
        const tableData = dataValue.map((item: Order) => {
          return {
            '0': item.channel_order_id,
            "1": item.id,
            '2': item.customer_name,
            '3': item.customer_email,
            '4': item.customer_phone,
            '5': item.created_at,
            '6': item.status,
            '7': <Button
              onPress={this.openOrderDetails.bind(this, item)}
              text="See Details"
              textStyle={{
                fontSize: 12,
                color: "#FFF"
              }}
            />,
          };
        });
        this.setState({ allOrderDetails: dataValue, tableNewData: tableData });
      }
    } else if (apiRequestCallId === this.getAllCourierApiCallId) {
      if (responseJson.data.status) {
        const courierData = responseJson.data.data.couriers.all.map((item: Courier) => {
          return {
            'key': item.courier_id,
            "name": item.courier_name,
          };
        });
        this.setState(
          {
            CourierData: responseJson.data.data.couriers.all,
            courierNewData: courierData
          },
          () => {
            this.selectCorierForAwb();
          }
        );
      }
    } else {
      this.checkResponseOrders(message);
    }
  };

  checkResponseOrders = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    this.setState({ loading: false });
    if (apiRequestCallId === this.getCancelOrder) {
      this.setState({
        shipmentDetailsToggle: !this.state.shipmentDetailsToggle,
      });
      this.getAllOrder();
      if (responseJson.data[0].data.status_code === 200) {
        this.showAlert("Message", responseJson.data[0].data.message);
      } else if (responseJson.data[0].status_code === 400) {
        this.showAlert("Message", responseJson.data[0].message);
      } else {
        this.showAlert("Message", responseJson.data[0].data.message);
      }
    } else if (apiRequestCallId === this.getAwbApi) {
      this.setState({ isLoadingAwb: false });
      if (responseJson.data && responseJson.data[0].status_code == 200) {
        this.showAlert("Message", responseJson.data[0].message);
        this.setState({
          SelectCourierModal: !this.state.SelectCourierModal,
        });
        this.getAllOrder();
      } else if (responseJson.data && responseJson.data[0].status_code == 422) {
        this.showAlert("Message", responseJson.data[0].errors);
      } else {
        this.showAlert("Message", responseJson.data[0].message);
      }
    } else {
      this.checkResponseOrdersNews(message);
    }
  };

  checkResponseOrdersNews = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    this.setState({ loading: false });
    if (apiRequestCallId == this.trackAwbsApi) {
      if (Platform.OS === "web") {
        if (
          responseJson.data &&
          responseJson.data[0]?.tracking_data?.status_code === 200
        ) {
          this.setState({
            orderTrackData: responseJson.data[0].tracking_data
          });
          this.setState({
            trackAwbMsg: "Awb is Tracked Successfully",
            trackAwbLoading: false,
          });
        } else {
          this.setState({
            trackAwbMsg: responseJson.data[0].errors[0].message,
            trackAwbLoading: false,
          });
        }
      } else if (responseJson.data[0]?.tracking_data?.status_code == 200) {
        this.setState({
          orderTrackData: responseJson.data[0].tracking_data,
          openOrdarModal: !this.state.openOrdarModal,
        });

        setTimeout(() => {
          this.setState({
            trackOrderDetailAgain: !this.state.trackOrderDetailAgain,
          });
        }, 500);
      } else {
        this.showAlert("Message", "This AWB is not shipped yet");
      }
    } else if (apiRequestCallId === this.generatePickupRequestApi) {
      let statusCode = responseJson.data[0].status_code;
      if (statusCode == 400) {
        this.showAlert("Message", responseJson.data[0].message);
      }
    } else {
      this.checkResponseOrdersButtons(message);
    }
  };

  checkResponseOrdersButtons = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    this.setState({ loading: false });
    if (apiRequestCallId === this.generateInvoiceApi) {
      let statusCode = responseJson.data[0].status_code;
      if (statusCode == 200) {
        if (Platform.OS === "web") {
          this.setState({ isLoadingInvoice: false });
          let linkURL = document.createElement("a");
          linkURL.href = responseJson.data[0].data.invoice_url;
          linkURL.setAttribute("data-test-id", "invoiceLink");
          linkURL.setAttribute("download", "label.pdf");
          document.body.appendChild(linkURL);
          linkURL.click();
          linkURL.parentNode && linkURL.parentNode.removeChild(linkURL);
        } else {
          let invoiceURL = responseJson.data[0].data.invoice_url;
          Linking.openURL(invoiceURL);
        }
      }
    } else if (apiRequestCallId === this.generatelabelApi) {
      this.generateLabaleApiResponse(message);
    } else {
      this.checkResponse2(message);
    }
  };
  generateLabaleApiResponse = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.generatelabelApi) {
      let statusCode = responseJson.data[0].status_code;
      if (statusCode == 200) {
        if (Platform.OS === "web") {
          let linkURL = document.createElement("a");
          linkURL.href = responseJson.data[0].data.label_url;
          linkURL.setAttribute("download", "label.pdf");
          document.body.appendChild(linkURL);
          linkURL.click();
          linkURL.parentNode && linkURL.parentNode.removeChild(linkURL);
        } else {
          let labelURL = responseJson.data[0].data.label_url;
          Linking.openURL(labelURL);
        }
      }
    }
  };
  checkResponse2 = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.generateMenifestApi) {
      if (Platform.OS === "web") {
        let statusCode = responseJson.data[0].status_code;
        if (statusCode == 200) {
          let linkURL = document.createElement("a");
          linkURL.href = responseJson.data[0].data.manifest_url;
          linkURL.setAttribute("download", "label.pdf");
          document.body.appendChild(linkURL);
          linkURL.click();
          linkURL.parentNode && linkURL.parentNode.removeChild(linkURL);
        } else if (responseJson.data && responseJson.data[0].message) {
          this.showAlert("Message", responseJson.data[0].message);
        }
      } else {
        let statusCode = responseJson.data[0].status_code;
        if (statusCode == 200) {
          let manifestURL = responseJson.data[0].data.manifest_url;
          Linking.openURL(manifestURL);
        } else {
          this.showAlert("Message", responseJson.data[0].message);
        }
      }
    }
  };

  changeOrderValue = (textValue: string) => {
    this.setState({
      orderShopCartId: textValue,
    });
  };

  createShiprocketOrder = () => {
    if (this.state.orderShopCartId == "") {
      this.showAlert("Message", "Please enter order id");
      return false;
    } else {
      this.shipRocketSend(this.state.orderShopCartId);
    }
  };

  shipRocketSend = async (orderId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.access_token,
    };

    const httpBody = {
      data: {
        order_id: orderId,
        order_date: "2024-08-14T07:45:39.590Z",
        pickup_location: "Default",
        channel_id: "",
        billing_customer_name: "Shreshth",
        billing_last_name: "J",
        billing_address: "jemma 8",
        billing_city: "Riyadh",
        billing_pincode: "88103",
        billing_state: "Riyadh Region",
        billing_country: "Saudi Arabia",
        billing_email: "bob.johnson@example.com",
        billing_phone: "1234567891",
        latitude: 21.4925,
        longitude: 39.17757,
        shipping_is_billing: 1,
        shipping_customer_name: "Shreshth",
        shipping_last_name: "J",
        shipping_address: "jemma 8",
        shipping_city: "Riyadh",
        shipping_pincode: "88103",
        shipping_country: "Saudi Arabia",
        shipping_state: "Riyadh Region",
        shipping_email: "bob.johnson@example.com",
        shipping_phone: "123456789",
        order_items: [
          {
            name: "Product 1",
            sku: "7-8",
            units: 1,
            selling_price: "800.0",
          },
          {
            name: "Product 2",
            sku: "7-9",
            units: 1,
            selling_price: "1000.0",
          },
          {
            name: "Product 3",
            sku: "8-9",
            units: 1,
            selling_price: "1500.0",
          },
        ],
        payment_method: "COD",
        shipping_charges: "0.0",
        total_discount: "0.0",
        sub_total: "1010.0",
        length: "1",
        breadth: "1",
        height: "1",
        weight: "1",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postShipRocketApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendShipRocketEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectCourier = (courierId: string) => {
    let orderId = this.state.shipmentDetailsData.id;
    this.generateAwb(orderId, courierId);
  };

  getAllCourierList = () => {
    const header = {
      token: this.state.access_token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCourierApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCourier + "?order_id=" + this.state.userOrderDetails.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openTrackLink = (trackURL: string) => {
    Linking.openURL("https://api.shiprocket.ae" + trackURL);
  };

  selectCourierChange = (
    evnt: { key: string, name: string }
  ) => {
    this.setState({ courierId: evnt.key,courierName: evnt.name  });
  };

  handleOrderIdChange = (event: string) => {
    this.setState({ orderShopCartId: event });
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      "token":""
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getFetchApi = async () => {
    this.getFetchApiDataApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_shiprocket_mena/shiprockets_mena/sm_configuration",
    });
  }

  // Customizable Area End
}
