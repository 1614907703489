// Customizable Area Start
import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Box,
} from "@material-ui/core";

import {Typography,Button} from "@builder/component-library"

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ICustomerDataThreeWeb, ICustomerDataTwoWeb } from "../types";
const configJSON = require("../config");

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: ".2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  shiprocketbtn: {
    backgroundColor: "#2297f8",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },
  popUpButton: {
    backgroundColor: "#2297f8",
    padding: "5px",
    borderRadius: "10px",
    color: "black",
    display: "block",
    width: "100%",
    margin: "8px",
  },

  disabledButton: {
    backgroundColor: "#808080",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  errorColor: {
    color: "red",
  },

  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },
  mainUpdateContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  doneButton: {
    color: "green",
    marginTop: "5px",
  },
  cancelButton: {
    color: "red",
    marginTop: "5px",
  },
}));

export type Props = {
  data:
    | ICustomerDataTwoWeb
    | ICustomerDataThreeWeb
    | {
        id: string;
        type: string;
        attributes: {
          order_items: {
            data: [];
          };
          ship_rocket_order_id: string;
          ship_rocket_awb_code: string;
          status: string;
          customer: {
            data: {
              attributes: {
                last_name: string;
                email: string;
                name: string;
                first_name: string;
                full_phone_number: string;
                ship_rocket_order_id: string;
                status: string;
                category: {
                  attributes: {
                    name: string;
                  };
                };
                sub_category: {
                  name: string;
                };
              };
            };
          };
        };
      };
  open: boolean;
  handleClose: () => void;
  handleNavigate: () => void;
  sendShipRocket: (orderId: string) => void;
  shipmentDetalsFetch: (orderId: string) => void;
  handleOrderStatusDetails: () => void;
  isLoadingGetShipRocketDetails: boolean;
  isLoadingSendShipRocket: boolean;
};

const CustomerDetailPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.open}>
        <Box className={classes.paper}>
          <Box className={classes.dimensionHeading}>
          <Typography
            >
            {configJSON.textCustomerDetails}
          </Typography>
          </Box>
          <Typography >
            {configJSON.textName} :{" "}
            {props.data.attributes.customer.data.attributes.first_name +
              " " +
              props.data.attributes.customer.data.attributes.last_name}
          </Typography>
          <Typography >
            {configJSON.textEmail} :{" "}
            {props.data.attributes.customer.data.attributes.email}
          </Typography>
          <Typography >
            {configJSON.textMobile} :{" "}
            {props.data.attributes.customer.data.attributes.full_phone_number}
          </Typography>
          <Box className={classes.dimensionHeading}>
          <Typography>
            {configJSON.textOrderDetails}
          </Typography>
          </Box>
          {props.data.attributes.order_items.data.length ? (
            <Box key={props.data.attributes.order_items.data[0].id}>
              <Typography >
                {configJSON.textPrice} :{" "}
                {props.data.attributes.order_items.data[0].attributes.price}
              </Typography>
              <Typography >
                {configJSON.textQuantity} :{" "}
                {props.data.attributes.order_items.data[0].attributes.quantity}
              </Typography>
              <Typography >
                {configJSON.textName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.name
                }
              </Typography>
              <Typography>
                {configJSON.textCategoryName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.category.attributes.name
                }
              </Typography>
              <Typography>
                {configJSON.textSubCategoryName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.sub_category.name
                }
              </Typography>
            </Box>
          ) : (
            <Typography>{configJSON.textNoOrder}</Typography>
          )}
          <Box style={customerDetailsWebStyle.buttonWrapperMain}>
            {!props.data.attributes.ship_rocket_order_id && (
              <Button
                style={
                  { backgroundColor: "#2297f8" }
                }
                data-test-id="sendShipRocketButton" onPress={() =>
                  props.data && props.sendShipRocket(props.data.id)
                }
                loading={props.isLoadingSendShipRocket} text={configJSON.textSendToShipRocket} />
            )}
            <Button
              style={
                { backgroundColor: "#2297f8" }
              }
              text={configJSON.textTrackOrder}
              onPress={props.handleOrderStatusDetails}>
            </Button>
            {props.data.attributes.status === "completed" ||
              props.data.attributes.status === "cancelled" ? null : (
              <Button
                style={
                  { backgroundColor: "#2297f8" }
                }
                text={configJSON.textUpdateOrder}
                onPress={props.handleNavigate}>
              </Button>
            )}

            {props.data.attributes.ship_rocket_order_id && (
              <Button
                style={
                  { backgroundColor: "#2297f8" }
                }
                loading={props.isLoadingGetShipRocketDetails}
                data-test-id="updateOrderButton"
                onPress={() =>
                  props.data && props.shipmentDetalsFetch(props.data.id)
                }
                text={configJSON.textViewShipRocketDetails} />
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomerDetailPopup;

const customerDetailsWebStyle = {
  buttonWrapperMain: {
    display:"flex",
    flexDirection: "column" as "column",
    gap: "8px"
  },
};

// Customizable Area End
