import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import {
  ICustomerDataTwoWeb,
  IShipHistory,
  OrderDataAttributesOrderItemWeb,
} from "./types";
import { setStorageData } from "../../../framework/src/Utilities";

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

export const configJSON = require("./config");

interface TableDataRow {
  '0': string;
  '1': string;
  '2': string | JSX.Element;
  '3': string;
}
// Customizable Area End


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: IShipHistory;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  tableData: {
    data: ICustomerDataTwoWeb[];
  };
  tableNewData: TableDataRow[];
  customerData:
    | ICustomerDataTwoWeb
    | {
        id: string;
        type: string;
        attributes: {
          order_items: {
            data: [];
          };
          ship_rocket_order_id: string;
          ship_rocket_awb_code: string;
          status: string;
          customer: {
            data: {
              attributes: {
                name: string;
                first_name: string;
                last_name: string;
                email: string;
                full_phone_number: string;
                ship_rocket_order_id: string;
                status: string;
                sub_category: {
                  name: string;
                };
                category: {
                  attributes: {
                    name: string;
                  };
                };
              };
            };
          };
        };
      };
  setOpen: boolean;
  detailsOpen: boolean;
  isLoadingSendShipRocket: boolean;
  shipToken: string;
  isLoadingGetShipRocketDetails: boolean;
  shipRocketModalData: {
    pickup_location?: string;
    net_total?: string;
    shipments?: {
      courier?: string;
      id?: string;
      awb?: string;
    };
    status?: string;
    awb_data: {
      awb: string;
    };
  } | null;
  data: {
    id?: string;
    attributes?: {
      ship_rocket_order_id?: string | boolean;
      ship_rocket_awb_code?: string;
    };
  } | null;
  showShipmentDataModal: boolean;
  isLoadingInvoice: boolean;
  isLoadingAwb: boolean;
  isLoadingLabel: boolean;
  awbError: string;
  labelDownloadError: string;
  isLoadingPickup: boolean;
  pickUpRequestError: string;
  isLoadingManifest: boolean;
  manifestError: string;
  isLoadingCancelOrder: boolean;
  orderId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ShiprocketIntegrateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tableDataGetApiCallId: string = "";
  customerDataApiCallId: string = "";
  postShipRocketApiCallId: string = "";
  shipRocketDetailsApiCallId: string = "";
  getInvoiceApiCallId: string = "";
  getAwbApiCallId: string = "";
  getLabelApiCallId: string = "";
  getRequestPickupApiCallId: string = "";
  getManifestApiCallId: string = "";
  postCancelOrderApiCallId: string = "";
  createTokenApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      tableData: { data: [] },
      tableNewData:[{
        0: "",
        1: "",
        2: "",
        3: ""
      }],
      customerData: {
        id: "",
        type: "",
        attributes: {
          order_items: {
            data: [],
          },
          ship_rocket_order_id: "",
          ship_rocket_awb_code: "",
          status: "",
          customer: {
            data: {
              attributes: {
                name: "",
                first_name: "",
                last_name: "",
                email: "",
                full_phone_number: "",
                ship_rocket_order_id: "",
                status: "",
                sub_category: {
                  name: "",
                },
                category: {
                  attributes: {
                    name: "",
                  },
                },
              },
            },
          },
        },
      },
      setOpen: false,
      detailsOpen: false,
      isLoadingSendShipRocket: false,
      shipToken: "",
      isLoadingGetShipRocketDetails: false,
      shipRocketModalData: null,
      showShipmentDataModal: false,
      isLoadingInvoice: false,
      isLoadingAwb: false,
      isLoadingLabel: false,
      awbError: "",
      labelDownloadError: "",
      isLoadingPickup: false,
      pickUpRequestError: "",
      isLoadingManifest: false,
      manifestError: "",
      data: null,
      isLoadingCancelOrder: false,
      orderId: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.tableDataGetApiCallId:
        this.tableDataCreate(responseJson)
        break;

      case this.customerDataApiCallId:
        this.setState({ customerData: responseJson, setOpen: true });
        break;

      case this.postShipRocketApiCallId:
        this.handleShipRocketPostData(message);
        break;

      case this.shipRocketDetailsApiCallId:
        this.handleShipRocketDetailsData(message);
        break;

      case this.getInvoiceApiCallId:
        this.handleDownloadInvoiceResponse(message);
        break;

      case this.getAwbApiCallId:
        this.handleAwbResponse(message);
        break;

      case this.getLabelApiCallId:
        this.handleLebelResponse(message);
        break;

      case this.getRequestPickupApiCallId:
        this.handleRequestPickupResponse(message);
        break;

      case this.getManifestApiCallId:
        this.handleManifestResponse(message);
        break;

      case this.postCancelOrderApiCallId:
        this.handleCancelOrderResponse(message);
        break;

        case this.createTokenApiCallId:
          this.createTokenAPiResponse(responseJson);
          break;
    }
    // Customizable Area End
  }

  // web events
  // Customizable Area Start
  tableDataCreate = (responseJson: {
    data: ICustomerDataTwoWeb[];
  }) => {
    this.setState({ tableData: responseJson });
  }
  
  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
      requestMessageApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
    return requestMessageApi.messageId;
  };

  onRegister = async () => {
    this.createTokenApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.createTokenApiEndPoint
    });
  };

  createTokenAPiResponse = async (responseJson : {token : string}) => {
   setStorageData("token",responseJson.token);
   const shipToken = responseJson.token;
   this.setState({ shipToken }, () => this.handleTableData());
  }

  handleShipRocketPostData = (message: Message) => {
    this.setState({
      isLoadingSendShipRocket: false,
      isLoadingGetShipRocketDetails: false,
      setOpen: false,
    });
    const requestMessageApi = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (requestMessageApi.data.status_code === 422 && requestMessageApi.data.errors) {
      let alertMessage = "";
      if (requestMessageApi.data.message) {
        alertMessage += `${requestMessageApi.data.message}\n`;
      }
      if (requestMessageApi.data.errors) {
        Object.keys(requestMessageApi.data.errors).forEach(
          (param) =>
            (alertMessage += `${requestMessageApi.data.errors[param][0]}\n`),
        );
      }
      this.showAlert("", alertMessage);
    } else {
      requestMessageApi.data && this.handleTableData();
    }
  };

  handleLebelResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    if (responseJson.data && !responseJson.data.label_url) {
      this.setState({ labelDownloadError: responseJson.data.response });
    } else {
      let link = document.createElement("a");
      link.href = responseJson.data.label_url;
      link.setAttribute("download", "label.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  handleDownloadInvoiceResponse = (message: Message) => {
    this.setState({ isLoadingInvoice: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    let link = document.createElement("a");
    link.href = responseJson.data.invoice_url;
    link.setAttribute("download", "invoice.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  };

  handleRequestPickupResponse = (message: Message) => {
    this.setState({ isLoadingPickup: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (!responseJson.response && responseJson?.message) {
      this.setState({ pickUpRequestError: responseJson.message });
    } else if (responseJson.pickup_status === 1) {
      this.setState({ pickUpRequestError: responseJson.response.data });
    } else {
      this.setState({
        pickUpRequestError: "Something went wrong, Please try again later.",
      });
    }
  };

  handleAwbResponse = (message: Message) => {
    this.setState({ isLoadingAwb: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.message) {
      this.setState({ awbError: responseJson.message });
    } else if (
      responseJson.response.data &&
      !responseJson.response.data.awb_code
    ) {
      this.setState({ awbError: responseJson.response.data.awb_assign_error });
    } else {
      const { awb_code } = responseJson.response.data;
      if (awb_code) {
        this.apiDetalsFetch(this.state.orderId);
      }
    }
  };

  handleManifestResponse = (message: Message) => {
    this.setState({ isLoadingManifest: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data && !responseJson.data.manifest_url) {
      this.setState({ manifestError: responseJson.data.message });
    } else {
      let link = document.createElement("a");
      link.href = responseJson.data.manifest_url;
      link.setAttribute("download", "menifest.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  apiLabel = async (orderId: string) => {
    this.setState({ isLoadingLabel: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getLabelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLabelEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiRequest = async (orderId: string) => {
    this.setState({ isLoadingPickup: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getRequestPickupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestPickupEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleShipRocketDetailsData = (message: Message) => {
    this.setState({ isLoadingGetShipRocketDetails: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (
      responseJson.status_code === 404 ||
      responseJson.message ||
      responseJson.error
    ) {
      this.setState({ shipRocketModalData: null });
      this.showAlert("", "Shipment Details not found.");
    } else {
      this.setState(
        {
          shipRocketModalData: responseJson.data,
          setOpen: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ showShipmentDataModal: true });
          }, 50);
        },
      );
    }
  };

  closeCell = () => {
    this.setState({ setOpen: false });
  };

  handleShipmentClose = () => {
    this.setState({
      showShipmentDataModal: false,
      awbError: "",
      labelDownloadError: "",
      manifestError: "",
      pickUpRequestError: "",
    });
  };

  handleTableData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.tableDataGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShippingCartOrderAPiEndPoint,
    );
    const header = {
      "Content-Type": configJSON.gettableDataApiContentType,
      "token": this.state.shipToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCustomerData = (data: ICustomerDataTwoWeb) => {
    this.setState({ customerData: data, setOpen: true });
  };

  handleCancelOrderResponse = (message: Message) => {
    this.setState({
      isLoadingCancelOrder: false,
      showShipmentDataModal: false,
      awbError: "",
      labelDownloadError: "",
      manifestError: "",
      pickUpRequestError: "",
    });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data && responseJson.data.message) {
      this.handleTableData();
      this.showAlert(
        responseJson.data.status_code === 200 ? "Success" : "Error",
        responseJson.data.message,
      );
    }
  };

  apiManifest = async (orderId: string) => {
    this.setState({ isLoadingManifest: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getManifestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getManifestEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiAwb = async (orderId: string) => {
    this.setState({ isLoadingAwb: true, orderId: orderId });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAwbApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAwbEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiDetalsFetch = async (orderId: string) => {
    this.setState({ isLoadingGetShipRocketDetails: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.shipRocketDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShipRocketEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiInvoice = async (idInvoice: string) => {
    this.setState({ isLoadingInvoice: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getInvoiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDownloadInvoiceAPiEndPoint + "/" + idInvoice,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDownloadInvoiceAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiShipRocket = async (orderId: string) => {
    this.setState({ isLoadingSendShipRocket: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postShipRocketApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendShipRocketEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  detailsCell = (
    orderData: OrderDataAttributesOrderItemWeb[],
    status: string,
    orderId: string,
  ) => {
    this.setState({
      setOpen: false,
      showShipmentDataModal: false,
    });
    this.props.navigation.navigate("OrderDetails");
    storage.set("orderDetails", JSON.stringify(orderData));
    storage.set("status", status);
    storage.set("orderId", orderId);
  };

  cancelCell = async (orderId: string) => {
    this.setState({ isLoadingCancelOrder: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postCancelOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelOrderEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateShiprocket = (route: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }

  handleNavigateToUpdate = () => {
    this.state.customerData &&
    storage.set("customerData", JSON.stringify(this.state.customerData));
    this.props.navigation.navigate("UpdateAddress");
  };
  handleOrderStatusDetails = () => {
    storage.set("customerDataId", this.state.customerData.id);
    storage.set("shiprocketOrderId", this.state.customerData.attributes.ship_rocket_order_id);
    this.handleNavigateShiprocket("TrackOrder")
  };
  async componentDidMount() {
    this.onRegister()
  }
  // Customizable Area End
}


