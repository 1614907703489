import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import storage from "framework/src/StorageProvider";
import { IUpdateHistory } from "./types";
export const configJSON = require("./config");

export interface AddressType {
  name: string;
  key: string;
}

// Customizable Area End


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: IUpdateHistory;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpen: boolean;
  enableButton: boolean;
  orderId: number;
  address: string;
  address2: string;
  city: string;
  stateName: string;
  country: string;
  pinCode: string;
  addressType: string;
  addressId: string;
  updateToken: string;
  isUpdatePopupOpen: boolean;
  length: string;
  breadth: string;
  height: string;
  weight: string;
  isOpenAddressType: boolean;
  displayAddressType: AddressType[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UpdateAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addressApiCallId: string = "";
  updateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpen: false,
      enableButton: true,
      orderId: 0,
      address: "",
      address2: "",
      city: "",
      stateName: "",
      country: "",
      pinCode: "",
      addressType: "",
      addressId: "",
      updateToken: "",
      isUpdatePopupOpen: false,
      length: "",
      breadth: "",
      height: "",
      weight: "",
      isOpenAddressType: false,
      displayAddressType:[
        {
          key: "home",
          name: "Home"
        },
        {
          key: "work",
          name: "Work"
        },
        {
          key: "other",
          name: "Other"
        }
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJsonSuccess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      switch (apiRequestCallId) {
        case this.addressApiCallId:
          if (
            responseJsonSuccess &&
            !responseJsonSuccess.error &&
            !responseJsonSuccess.errors
          ) {
            this.handleAddressResponse(message);
          } else {
            this.showAlert(
              `Error`,
              `Pincode ${Object.entries(responseJsonSuccess.errors[0])[0][1]}`,
            );
          }
          break;

        case this.updateApiCallId:
          if (
            responseJsonSuccess &&
            !responseJsonSuccess.error &&
            !responseJsonSuccess.errors
          ) {
            this.setState({ isUpdatePopupOpen: false });
            this.props.navigation.navigate("ShiprocketIntegrate");
          } else {
            this.showAlert(
              `Error`,
              responseJsonSuccess.errors.invalid_request[0] ||
                responseJsonSuccess.error ||
                configJSON.updateOrderErrorMsg,
            );

            this.setState({ isUpdatePopupOpen: false });
          }
          break;

        default:
          this.showAlert(`Error`, configJSON.commonErrorMsg);
        }
      }
        // Customizable Area End
      }
      // Customizable Area Start
  async componentDidMount() {
    const updateToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTUsImV4cCI6MTc1NTY3OTIwNiwidG9rZW5fdHlwZSI6ImxvZ2luIn0.yNWMp_uaaw-BAlpD9CFRDc0drSU-8hsFdmIZcGG8VX--NO49sVseyh_86EaTT91dDaKLYYoWiz7S6QWf2rYs3Q";
    const customerData = await storage.get("customerData");
    let dataFromStorage = JSON.parse(customerData)
    this.setState({
      updateToken,
      orderId: dataFromStorage.id,
    });
    const addressDataWeb = dataFromStorage.attributes.address.data;
    const orderDimension = dataFromStorage.attributes;
    addressDataWeb &&
      this.setState({
        addressId: addressDataWeb.id,
        address: addressDataWeb.attributes.address,
        address2: addressDataWeb.attributes.address2,
        city: addressDataWeb.attributes.city,
        stateName: addressDataWeb.attributes.state,
        pinCode: addressDataWeb.attributes.pincode
          ? addressDataWeb.attributes.pincode.toString()
          : "",
        addressType: addressDataWeb.attributes.address_type,
        country: addressDataWeb.attributes.country,
        length: orderDimension.length,
        breadth: orderDimension.breadth,
        height: orderDimension.height,
        weight: orderDimension.weight,
      });
  }

  handleAddressResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.message) {
      this.showAlert("", responseJson.message);
    } else {
      this.setState({
        addressId: responseJson.data.id,
        isUpdatePopupOpen: true,
      });
    }
  };

  isNonNullAndEmpty(value: string) {
    return value !== undefined && value !== null && value !== "";
  }

  onAddingNewAddress = async () => {
    if (
      !this.isNonNullAndEmpty(this.state.address) ||
      !this.isNonNullAndEmpty(this.state.address2) ||
      !this.isNonNullAndEmpty(this.state.city) ||
      !this.isNonNullAndEmpty(this.state.stateName) ||
      !this.isNonNullAndEmpty(this.state.country) ||
      !this.isNonNullAndEmpty(this.state.pinCode) ||
      !this.isNonNullAndEmpty(this.state.addressType)
    ) {
      this.showAlert("Alert", configJSON.fieldsEmptyWarningMsg);
    } else {
      this.setState({ enableButton: false }, () => {
        this.createAddress();
      });
    }
  };
  validateOrderUpdate = () => {
    if (
      !this.isNonNullAndEmpty(this.state.length) ||
      !this.isNonNullAndEmpty(this.state.breadth) ||
      !this.isNonNullAndEmpty(this.state.weight) ||
      !this.isNonNullAndEmpty(this.state.height) ||
      this.state.length === "0" ||
      this.state.breadth === "0" ||
      this.state.height === "0" ||
      this.state.weight === "0"
    ) {
      this.setState({ isUpdatePopupOpen: false });
      this.showAlert("Alert", configJSON.fieldsEmptyWarningMsg);
    } else {
      this.orderUpdateWeb();
    }
  };
  createAddress = async () => {
    const {
      address,
      address2,
      city,
      stateName,
      country,
      addressType,
      pinCode,
    } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.updateToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.addressApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressCreateEndPoint,
    );
    const httpBody = {
      address: {
        address: address,
        address2: address2,
        city: city,
        state: stateName,
        country: country,
        pincode: pinCode,
        address_type: addressType,
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  orderUpdateWeb = async () => {
    this.setState({ isUpdatePopupOpen: true });
    const { addressId, length, breadth, height, weight, orderId } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.updateToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.updateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShippingCartOrderAPiEndPoint}/${orderId}`,
    );
    const httpBody = {
      data: {
        attributes: {
          address_id: addressId,
          length,
          breadth,
          height,
          weight,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handelInputChange = (event: string, name: string) => {
    this.setState({
      ...this.state,
      [name]: event,
    });
  };

  handleDropdownAddress = () => {
    this.setState(prevState => ({ isOpenAddressType: !prevState.isOpenAddressType }));
  };

  handleInputSelect = (
    event: { key: string, name: string }
  ) => {
    this.setState({ addressType: event.name, isOpenAddressType: false });
  };

  navigateToOrderList = () => {
    this.props.navigation.navigate("ShiprocketIntegrate");
  };
  handleUpdatePopupClose = () => this.setState({ isUpdatePopupOpen: false });
  // Customizable Area End
}


