import React from 'react';
// Customizable Area Start
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import ShiprocketController, { Props } from './StripeConnectController.web';
import { Box, Grid, styled } from '@material-ui/core';
import { Button, Input, Typography } from '@builder/component-library';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
const stripePromise = loadStripe('pk_test_51PHNn8K0SejcsOSKZ2Gl0JZDiIs6XhQavAA7uqwXPxBtWe1sWTuoLp5w1K1bx6j9pMc0rULX0JAnPp2y2tsFeX9400BiRjRYSQ');
// Customizable Area End

export default class PaymentForm extends ShiprocketController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  renderCheckoutFormBase = () => {
    return (
      <Elements
        data-test-id="elementTestId"
        stripe={stripePromise} options={{ clientSecret: this.state.clientSecret }}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <>
              <PaymentElement />
              <Button disabled={!stripe}
                style={{ ...customerDetailsWebStyle.buttonWrapper, width: "10%" }}
                onPress={() => this.handleSubmit(stripe, elements)}
                text='Pay'
              >
              </Button>
            </>
          )}
        </ElementsConsumer>
      </Elements>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {!this.state.stripForm &&
          <MainContainer container spacing={3}>
            <Grid item lg={3}>
              <Input
                data-test-id="emailTestId"
                placeholder="Email"
                label='Email'
                onChangeText={this.handleUserEmailInput}
              />
              {(this.state.emailError || this.state.blankEmailError) &&
                <Typography style={{ color: "red" }} children={this.state.emailErrorMsg}></Typography>
              }
            </Grid>
            <Grid item lg={3}>
              <Button
                onPress={this.createAccount}
                text='Create Account'
                style={customerDetailsWebStyle.buttonWrapper}
                textStyle={customerDetailsWebStyle.buttonTextWrapper}
              >
              </Button>
            </Grid>
            <Grid item lg={3}>
              <Input
                data-test-id="merchantTestId"
                placeholder="Merchant Id"
                label='Merchant Id'
                value={this.state.merchantId}
              />
            </Grid>
            <Grid item lg={3}>
              <Button
                onPress={this.getOnboardingLink}
                text='Get Onboarding Link'
                style={customerDetailsWebStyle.buttonWrapper}
                disabled={this.state.merchantId.length === 0}
              >
              </Button>
            </Grid>
            <Grid item lg={12}>
              {this.state.urlForOnboarding &&
                <a data-test-id="onBoardingTestId" href={this.state.urlForOnboarding} target="_blank" rel="noopener noreferrer">
                  Your Onboarding Link
                </a>
              }
            </Grid>
            <Grid item lg={12} className='seprationCenter'>
              -------------------------***************-----------------------------------
            </Grid>
            <Grid item lg={3}>
              <Typography style={{ fontWeight: "bold" }}>Region</Typography>
              <Box sx={{ marginTop: 2, position: 'relative' }}>
                <Box
                  data-test-id="regionTestId"
                  onClick={this.handleDropdownRegion}
                  className='selectInput'
                >
                  <Typography data-test-id="regionValueTestId" children={this.state.region.name ? this.state.region.name : 'Select Region'} />
                  {this.state.isOpenRegion ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
                {this.state.isOpenRegion && (
                  <Box
                    className='selectOption'
                  >
                    {this.state.displayRegion.map((region, index) => (
                      <Box
                        data-test-id={`regionSelectTestId${index}`}
                        key={region.key}
                        onClick={() => this.handleSelectRegion(region)}
                        className='options'
                      >
                        <Typography
                          data-test-id={`regionOptionsTestId${index}`}
                        >{region.name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={3}>
              <Typography style={{ fontWeight: "bold" }}>Currency</Typography>
              <Box sx={{ marginTop: 2, position: 'relative' }}>
                <Box
                  data-test-id="currencyTestId"
                  onClick={this.handleDropdownCurrecny}
                  className='selectInput'
                >
                  <Typography data-test-id="currencyValueTestId">
                    {this.state.currency.key ? this.state.currency.key : 'Select Currency'}
                  </Typography>
                  {this.state.isOpenCurrency ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
                {this.state.isOpenCurrency && (
                  <Box
                    className='selectOption'
                  >
                    {this.state.displayCurrency.map((currency, index) => (
                      <Box
                        data-test-id={`currencySelectTestId${index}`}
                        key={currency.key}
                        onClick={() => this.onChangeCurrency(currency)}
                        className='options'
                      >
                        <Typography
                          data-test-id={`currencyOptionsTestId${index}`}
                        >{currency.name}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              {this.state.blankCurrencyError &&
                <Typography style={{ color: "red" }} children={'Please select currency'}></Typography>
              }
            </Grid>

            <Grid item lg={3}>
              <Input
                data-test-id="amountTestId"
                placeholder="Amount"
                label='Amount'
                onChangeText={this.onChangeText}
              />
              {this.state.blankAmountError &&
                <Typography style={{ color: "red" }} children={'Please enter amount'}></Typography>
              }
            </Grid>
            <Grid item lg={3}>
              <Button
                onPress={this.checkout}
                text='Checkout'
                style={customerDetailsWebStyle.buttonWrapper}
                textStyle={customerDetailsWebStyle.buttonTextWrapper}
              >
              </Button>
            </Grid>
          </MainContainer>
        }
        <MainStripContainer>
          {this.state.stripForm && this.renderCheckoutFormBase()}
        </MainStripContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const customerDetailsWebStyle = {
  buttonWrapper: {
    backgroundColor: "rgb(34, 151, 248)",
    marginTop: "14px",
    padding: "10px 5px 10px 5px",
    borderRadius: 10,
    width: "100%",
    border: "1px solid grey"
  },
  buttonTextWrapper: {
    color: "#ffffff",
  },
}
const MainContainer = styled(Grid)({
  padding: "40px 15px",
  justifyContent: "center",
  alignItems: "center",
  "& .seprationCenter": {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  "& .box-main": {
    height: "100%"
  },
  "& .gridCross": {
    position: "relative",
  },
  "& .crossIcon": {
    cursor: "pointer",
    position: "absolute",
    right: "38%",
  },
  "& .selectOption": {
    position: 'absolute',
    border: '1px solid #ccc',
    width: '200px',
    backgroundColor: '#fff',
    zIndex: 1,
  },
  "& .options": {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#f0f0f0' },
  },
  "& .selectInput": {
    border: '1px solid #ccc',
    padding: '10px',
    cursor: 'pointer',
    width: '200px',
    display: "flex",
    justifyContent: "space-between",
  }
});

const MainStripContainer = styled(Grid)({
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  gap: "12px",
  "& .box-main": {
    height: "100%"
  }
});
// Customizable Area End