import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";

import {
  Typography, Button, Input,
  DropDown
} from "@builder/component-library";

import { Product } from "./types";

// Customizable Area End

import ShiprocketController, {
  Props,
  configJSON,
} from "./ShiprocketController";

export default class Shiprocket extends ShiprocketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  shipRocketDetailsModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        data-test-id="SRDetailsModal"
        open={this.state.shipmentDetailsToggle}
        onClose={this.backToHomeScreenDetails}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          ...shiprocketDetailsWebStyle.modal,
          width: "35%",
          margin: "auto",
        }}
      >
        <Fade in={this.state.shipmentDetailsToggle}>
          <Box style={{ ...shipWebStyle.paper, width: "100%" }}>
            <Typography component="h5">Shipment Details</Typography>
            <Box>
              <Typography>
                {`Pickup Location : ${this.state.userOrderDetails.pickup_location}`}
              </Typography>
              <Typography>
                {`Total Amount: ${this.state.userOrderDetails.total}`}
              </Typography>
              <Typography>
                Status: {this.state.userOrderDetails.status}
              </Typography>
              <Typography>
                {`Courier: ${this.state.userOrderDetails.shipments[0].courier}`}
              </Typography>
              <Typography>
                {`Shipment id: ${this.state.userOrderDetails.shipments[0].id}`}
              </Typography>
              <Typography>
                AWB code: {this.state.userOrderDetails.shipments[0].awb}
              </Typography>
            </Box>
            <Button
              data-test-id="orderViewItems"
              style={customerDetailsWebStyle.buttonWrapper}
              onPress={this.viewOrderItemsDetails.bind(
                this,
                this.state.userOrderDetails
              )}
              text="View Order Items"
            >
            </Button>
            <Button
              style={customerDetailsWebStyle.buttonWrapper}
              onPress={this.generateInvoice.bind(
                this,
                String(this.state.userOrderDetails.id)
              )}
              data-test-id="handleInvoiceButton"
              text={"Download Invoice"}
            >
            </Button>
            {this.state.userOrderDetails.status.toUpperCase() !==
              "CANCELED" && (
                <Box>
                  <Button
                    data-test-id="generateAwbButton"
                    style={customerDetailsWebStyle.buttonWrapper}
                    onPress={this.getAllCourierList.bind(this)}
                    text={"Generate AWB"}
                  >
                  </Button>

                  {this.state.userOrderDetails.shipments[0].awb.length > 0 && (
                    <Button
                      data-test-id="generateLabelButton"
                      style={customerDetailsWebStyle.buttonWrapper}
                      onPress={() =>
                        this.generatelabel(
                          this.state.userOrderDetails.shipments[0].id
                        )
                      }
                      text={"Generate Label"}
                    >
                    </Button>
                  )}
                  {this.state.userOrderDetails.shipments[0].awb.length > 0 && (
                    <Button
                      data-test-id="generateManifestButton"
                      style={{ ...customerDetailsWebStyle.buttonWrapper }}
                      onPress={() =>
                        this.generateMenifest(
                          this.state.userOrderDetails.shipments[0].id
                        )
                      }
                      disabled={this.state.userOrderDetails.activities.includes(
                        "MANIFEST_SCHEDULED"
                      )}
                      text={"Generate Manifest"}
                    >
                    </Button>
                  )}
                  {
                    <Button
                      data-test-id="generatePickUpBtn"
                      style={{ ...customerDetailsWebStyle.buttonWrapper }}
                      onPress={() =>
                        this.generatePickupRequest(
                          this.state.userOrderDetails.shipments[0].id
                        )
                      }
                      text={"Generate Pickup"}
                    >
                    </Button>
                  }
                  {
                    <Button
                      data-testid="orderCancel"
                      style={customerDetailsWebStyle.buttonWrapper}
                      onPress={this.cancelOrder.bind(
                        this,
                        String(this.state.userOrderDetails.id)
                      )}
                      text={"Cancel Order"}
                    >
                    </Button>
                  }
                </Box>
              )}
          </Box>
        </Fade>
      </Modal>
    );
  };

  generateCouriesModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        data-test-id="courierModal"
        open={this.state.SelectCourierModal}
        onClose={this.viewShipMentDetailsAgainAwb}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          ...shiprocketDetailsWebStyle.modal,
          width: "35%",
          margin: "auto",
        }}
      >
        <Fade in={this.state.SelectCourierModal}>
          <Box
            style={{
              ...shipWebStyle.paper,
              width: "100%",
              height: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <DropDown
                containerStyle={{ height: 50, marginTop: 16 }}
                listContainerStyle={{ height: 10 }}
                data-test-id="courierInput"
                placeHolder={this.state.courierName ? this.state.courierName : "Select Courier"}
                options={this.state.courierNewData}
                placeHolderLabel={"Select Courier"}
                onSelect={this.selectCourierChange}
              />
            </Box>
            <Button
              data-test-id="generateAwbWithCourier"
              style={customerDetailsWebStyle.buttonWrapper}
              onPress={this.generateAwb.bind(
                this,
                this.state.userOrderDetails.id,
                this.state.courierId
              )}
              text={"Generate AWB"}
            >
            </Button>
          </Box>
        </Fade>
      </Modal>
    );
  };
  trackOrderDetailModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.trackOrderDetail}
        onClose={this.backToHomeScreen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          ...shiprocketDetailsWebStyle.modal,
          width: "35%",
          margin: "auto",
        }}
      >
        <Fade in={this.state.trackOrderDetail}>
          <Box style={shipWebStyle.paper}>
            <Typography
              component="h5"
              style={shipWebStyle.trackMainTitle}
            >
              Tracking Status
            </Typography>
            <Box>
              <Box style={shipWebStyle.trackCardWeb}>
                <Typography style={shipWebStyle.textStyleWeb}>
                  {this.state.userOrderDetails.status}
                </Typography>
                <Typography style={shipWebStyle.textStyleWeb}>
                  {`Date : ${this.state.userOrderDetails.created_at}`}
                </Typography>
                <Typography style={shipWebStyle.textStyleWeb}>
                  {`Location : ${this.state.userOrderDetails.customer_address}`}
                </Typography>
              </Box>
              {this.state.orderTrackData.shipment_track[0].id ? (
                <Box>
                  <Typography style={shipWebStyle.textStyleWeb}>
                    Origin :{" "}
                    {this.state.orderTrackData.shipment_track[0].origin}
                  </Typography>
                  <Typography style={shipWebStyle.textStyleWeb}>
                    {`PickUp Date : ${this.state.orderTrackData.shipment_track[0].pickup_date}`}
                  </Typography>
                  <Typography style={shipWebStyle.textStyleWeb}>
                    Destination :{" "}
                    {this.state.orderTrackData.shipment_track[0].destination}
                  </Typography>
                  <Typography style={shipWebStyle.textStyleWeb}>
                    {`Delivery Date : ${this.state.orderTrackData.shipment_track[0].delivered_date}`}
                  </Typography>
                  <Typography style={shipWebStyle.textStyleWeb}>
                    Current Status :{" "}
                    {this.state.orderTrackData.shipment_track[0].current_status}
                  </Typography>
                </Box>
              ) : null}
              {this.state.orderTrackData.shipment_track_activities[0].status
                ? this.state.orderTrackData.shipment_track_activities.map(
                  (activity) => (
                    <Box>
                      <Typography style={shipWebStyle.trackLastMainTitle}>
                        Last Activity
                      </Typography>
                      <Typography style={shipWebStyle.textStyleWeb}>
                        Date : {activity.date}
                      </Typography>
                      <Typography style={shipWebStyle.textStyleWeb}>
                        Location : {activity.location}
                      </Typography>
                      <Typography style={shipWebStyle.textStyleWeb}>
                        Activity : {activity.activity}
                      </Typography>
                    </Box>
                  )
                )
                : null}
            </Box>

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box sx={shipWebStyle.goBackButtonContainer}>
                <Button
                  onPress={this.trackAwb.bind(
                    this,
                    this.state.userOrderDetails.shipments[0].awb
                  )}
                  style={customerDetailsWebStyle.buttonWrapper}
                  text={"Track Awb"}
                >
                </Button>
              </Box>
              <Box sx={shipWebStyle.goBackButtonContainer}>
                <Button
                  data-testid="goBackToOrder"
                  onPress={this.backToHomeScreen}
                  text="Go back to orders"
                >
                </Button>
              </Box>
            </Box>
            {this.state.trackAwbMsg}
          </Box>
        </Fade>
      </Modal>
    );
  };

  showOrderItemDetails = () => {
    return (
      <Modal
        data-test-id="showOrderModal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.orderItemsDetails}
        onClose={this.viewShipMentDetailsAgain}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          ...shiprocketDetailsWebStyle.modal,
          width: "35%",
          margin: "auto",
        }}
      >
        <Fade in={this.state.orderItemsDetails}>
          <Box style={{ ...shipWebStyle.paper, width: "100%" }}>
            <Typography>Order Items List</Typography>
            {this.state.userOrderDetails.products.map(
              (item: Product, index: number) => {
                return (
                  <Box style={shipWebStyle.trackCardWeb} key={item.id}>
                    <Typography
                      style={{
                        ...shipWebStyle.textStyleWeb,
                        textDecorationLine: "underline",
                      }}
                    >
                      {`Order Item ${index + 1}`}
                    </Typography>
                    <Typography style={shipWebStyle.textStyleWeb}>
                      {`Price : ${item.product_cost}`}
                    </Typography>
                    <Typography style={shipWebStyle.textStyleWeb}>
                      {`Quantity : ${item.quantity}`}
                    </Typography>
                    <Typography style={shipWebStyle.textStyleWeb}>
                      {`Name : ${item.name}`}
                    </Typography>
                  </Box>
                );
              }
            )}
            <Box display={"flex"} width="100%" justifyContent={"center"}>
              <Button
                data-test-id="goBackToOrdersBtn"
                onPress={this.viewShipMentDetailsAgain}
                text="Go back to orders"
              >
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };
  openOrderDetailsModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        data-test-id="orderDetailsModal"
        style={shipWebStyle.modal}
        open={this.state.openOrdarModal}
        onClose={this.backToHomeScreenMain}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.openOrdarModal}>
          <Box style={shipWebStyle.paper}>
            <Typography
              style={shipWebStyle.dimensionHeading}
            >
              Customer Details
            </Typography>
            <Typography>
              {`${this.state.userOrderDetails.customer_name}`}
            </Typography>
            <Typography>
              {`Email : ${this.state.userOrderDetails.customer_email}`}
            </Typography>
            <Typography>
              {`Mobile : ${this.state.userOrderDetails.customer_phone}`}
            </Typography>

            <Typography
              style={shipWebStyle.dimensionHeading}
            >
              Order Details
            </Typography>
            {this.state.userOrderDetails.products.length > 0 ? (
              <Box key={this.state.userOrderDetails.products[0].id}>
                <Typography>
                  Price : {this.state.userOrderDetails.products[0].product_cost}
                </Typography>
                <Typography>
                  {`Quantity : ${this.state.userOrderDetails.products[0].quantity}`}
                </Typography>
                <Typography>
                  Name : {this.state.userOrderDetails.products[0].name}
                </Typography>
              </Box>
            ) : (
              <Typography>{configJSON.textNoOrder}</Typography>
            )}
            <Box>
              <Button
                style={customerDetailsWebStyle.buttonWrapper}
                onPress={this.orderDetailsTracking.bind(
                  this,
                  this.state.userOrderDetails
                )}
                text="Track Order"
              >
              </Button>
              <Button
                data-test-id="viewSRDetailsBtn"
                style={customerDetailsWebStyle.buttonWrapper}
                onPress={this.viewShipMentDetails.bind(
                  this,
                  this.state.userOrderDetails
                )}
                text="View Shiprocket Details"
              >
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Container>
          <Typography component="h4" style={shipWebStyle.shipMainWebTitle}>
            Orders Table
          </Typography>
          <Box display={"flex"} width={"25%"} mb={"3rem"}>
            <Input
              value={this.state.orderShopCartId}
              onChangeText={this.handleOrderIdChange}
              data-test-id="orderIdInpBox"
              placeholder="Enter Order ID"
            />
            <Button
              data-test-id="orderInpBtn"
              style={{
                ...customerDetailsWebStyle.buttonWrapper,
                width: "195px",
                marginLeft: "2rem",
              }}
              onPress={this.createShiprocketOrder.bind(this)}
              text="Send to ShipRocket"
            >
            </Button>
          </Box>
          <Box>
            <Box sx={tableStyle.container}>
              <Box sx={tableStyle.header}>Order no</Box>
              <Box sx={tableStyle.header}>SR Order no</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Customer Name</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Email</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Phone Number</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Order Date</Box>
              <Box sx={{ ...tableStyle.header, ...tableStyle.cellRight }}>Status</Box>

              {this.state.allOrderDetails.map((item, index) => (
                <Box
                  key={index}
                  data-test-id="tableRowId"
                  sx={tableStyle.row}
                  onClick={() => this.openOrderDetails(item)}
                >
                  <Box sx={tableStyle.cell}>{item.channel_order_id}</Box>
                  <Box sx={tableStyle.cell}>{item.id}</Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                  {item.customer_name}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.customer_email}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.customer_phone}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.created_at}
                  </Box>
                  <Box sx={{ ...tableStyle.cell, ...tableStyle.cellRight }}>
                    {item.status}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          {this.generateCouriesModal()}
          <Box>
            {this.state.userOrderDetails.id && (
              <Box>
                {this.openOrderDetailsModal()}
                {this.shipRocketDetailsModal()}
                {this.state.CourierData.length &&
                  this.generateCouriesModal()}
                {this.trackOrderDetailModal()}
                {this.showOrderItemDetails()}
              </Box>
            )}
          </Box>
        </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const tableStyle = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 2,
    alignItems: 'center',
    padding: 2,
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  header: {
    fontWeight: 'bold',
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderBottom: '2px solid #ddd',
  },
  cell: {
    cursor: 'pointer',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  cellRight: {
    textAlign: 'right',
  },
  mainTitle: {
    color: 'rgb(98, 0, 238)',
  },
  row: {
    display: 'contents',
  },
};

const shipWebStyle = {
  trackLastMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  shipMainWebTitle: {
    color: "#6200EE",
    textAlign: "center" as "center",
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
  },
  paper: {
    backgroundColor: "white",
    border: ".2px solid #000",
    padding: "15px",
    borderRadius: "10px",
  },
  textStyleWeb: {
    color: "#000",
    fontSize: 16,
  },
  trackMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    textAlign: "center" as "center"
  },
  trackCardWeb: {
    padding: "10px",
    margin: "10px",
    border: "1px solid rgb(98, 0, 238)",
  },

  paperContainer: {
    width: "100%",
    overflow: "hidden" as "hidden",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorColor: {
    color: "red",
  },
  shiprocketbtn: {
    backgroundColor: "#2297f8",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  disabledButton: {
    backgroundColor: "#808080",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  doneButton: {
    color: "green",
    marginTop: "5px",
  },

  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },

  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },

  mainUpdateContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },

  cancelButton: {
    color: "red",
    marginTop: "5px",
  },
  goBackButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popUpButton: {
    backgroundColor: "#2297f8",
    padding: "5px",
    borderRadius: "10px",
    color: "black",
    display: "block",
    width: "100%",
    margin: "8px",
  },
};
const customerDetailsWebStyle = {
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: 10,
    color: "#ffffff",
    width: "100%",
  },
  disableButtonWrapper: {
    backgroundColor: "#808080",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  errorText: {
    color: "red",
  },

  titleText: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    marginBottom: "10px",
  },
  trackMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  trackCardWeb: {
    padding: "10px",
    margin: "10px",
    border: "1px solid rgb(98, 0, 238)",
  },

  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  goBackButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  generateAwbBtnWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "24%",
    marginRight: "25px",
  },
};

const shiprocketDetailsWebStyle = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popUpButton: {
    backgroundColor: "#2297f8",
    padding: "5px",
    borderRadius: "10px",
    color: "black",
    display: "block",
    width: "100%",
    margin: "8px",
  },
  shiprocketbtn: {
    backgroundColor: "#2297f8",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },
  paper: {
    border: ".2px solid #000",
    padding: "2px 4px 3px",
    borderRadius: "10px",
  },
  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },
  disabledButton: {
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    backgroundColor: "#808080",
    padding: "10px 5px 10px 5px",
    width: "100%",
  },

  errorColor: {
    color: "red",
  },
  doneButton: {
    color: "green",
    marginTop: "5px",
  },

  mainUpdateContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },

  cancelButton: {
    color: "red",
    marginTop: "5px",
  },
  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
};
// Customizable Area End
