import React from 'react';
// Customizable Area Start
import ShiprocketController, { Props } from './StripeConnectController.web';
import { Box, Dialog, styled } from '@material-ui/core';
import { Button, Typography } from '@builder/component-library';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
// Customizable Area End

export default class PaymentForm extends ShiprocketController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <MainContainerDialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="main-box">
                    {this.state.redirectStatus === "succeeded" ? <CheckCircleIcon className='image' /> : <SmsFailedIcon className='imageFaild' />}
                    <Typography>
                        {this.state.redirectStatus === "succeeded" ? "Payment successfully completed !" : "Payment failed please try again later !"}
                    </Typography>

                    <Button data-test-id="goBackTestId" text='Go Back' onPress={() => this.handleNavigate("StripeConnect")}></Button>
                </Box>
            </MainContainerDialog>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainerDialog = styled(Dialog)({
    padding: "40px 15px",
    "& .main-box": {
        padding: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "15px"
    },
    "& .image": {
        fontSize: "90px",
        color: "green"
    },
    "& .imageFaild": {
        fontSize: "90px",
        color: "red"
    }
});
// Customizable Area End