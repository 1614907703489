import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Alert, TouchableOpacityProps , Platform} from "react-native";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { NavigationStackProp } from "react-navigation-stack";
// Customizable Area End

const FormData = require('form-data');

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordInput: string;
  passwordField: boolean;
  newPasswordInput: string;
  newPasswordField: boolean;
  showChangePass: boolean;
  showDeleteAcc: boolean;
  isEnabled: boolean;
  logoutModal: boolean;
  delAccModal: boolean;
  changePassModal: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FeatureSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePasswordCallId: string = '';
  logoutCallId: string = '';
  deleteAccountCallId: string = '';
  notifiPreferenceCallId: string = '';
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.toggleSwitch = this.toggleSwitch.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      passwordInput: "",
      passwordField: true,
      newPasswordInput: "",
      newPasswordField: true,
      showChangePass: false,
      showDeleteAcc: false,
      isEnabled: false,
      logoutModal: false,
      delAccModal: false,
      changePassModal: false
    };

    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(errorReponse) {
        Alert.alert("Error", JSON.stringify(errorReponse));
        return;
      }
      if (this.changePasswordCallId === apiRequestCallId) {
        this.showAlert("Password Changed", JSON.stringify(responseJson.message))
      } else if (this.logoutCallId === apiRequestCallId) {
        this.showAlert("Logout", JSON.stringify(responseJson.message))
      } else if (this.deleteAccountCallId === apiRequestCallId) {
        this.showAlert("Account Deleted", JSON.stringify(responseJson.message))
        // this.logout();
      } else if (this.notifiPreferenceCallId === apiRequestCallId) {
        this.setState({
          isEnabled: responseJson.data.attributes.event_notification
        })
        if(responseJson.data.attributes.event_notification === true) {
          this.showAlert("Notification", "Enabled")
        } else {
          this.showAlert("Notification", "Disabled")
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onChangePassClicked = () => {
    this.setState({
      showChangePass: !this.state.showChangePass
    })
  }

  onDeleteClicked = () => {
    this.setState({
      showDeleteAcc: !this.state.showDeleteAcc
    })
  }


  toggleSwitch = () => {
    this.setState({
      isEnabled: !this.state.isEnabled
    }, () => {
      this.notificationPreference(this.state.isEnabled)
    })
  }

  notificationPreference = async (isEnabled: boolean) => {
    const token = configJSON.token;
    let header = { 
      token: token
    };
    let httpBody = new FormData(); 

    httpBody.append('data[attributes][event_notification]', isEnabled);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notifiPreferenceCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationPreferenceEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  newPasswordInputMobileProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordInput: text });
    },
    secureTextEntry: true,
  };

  passwordInputMobileProps = {
    onChangeText: (text: string) => {
      this.setState({ passwordInput: text });
    },
    secureTextEntry: true,
  };

  showNewPasswordBtnProps:TouchableOpacityProps = {
    onPress: () => {
      this.setState({ newPasswordField: !this.state.newPasswordField });
      this.newPasswordInputMobileProps.secureTextEntry = !this.state.newPasswordField;
      this.showNewPasswordImageProps.source = this.newPasswordInputMobileProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  showNewPasswordImageProps = {
    source: this.newPasswordInputMobileProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  showPasswordBtnProps = {
    onPress: () => {
      this.setState({ passwordField: !this.state.passwordField });
      this.passwordInputMobileProps.secureTextEntry = !this.state.passwordField;
      this.showPasswordImageProps.source = this.passwordInputMobileProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  showPasswordImageProps = {
    source: this.passwordInputMobileProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  
  logoutBtnProps = {
    onPress: () => this.logoutAlert(),
  }

  setPasswordField = () => {
    this.setState({ passwordField: !this.state.passwordField });
  };

  setNewPasswordField = () => {
    this.setState({ newPasswordField: !this.state.newPasswordField });
  };

   logout = async () => {
    this.setState({
      logoutModal: false
    })
    const token = configJSON.token
    let header = { token };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoutCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   changePassword = async () => {
    this.setState({
      changePassModal: false
    })
    const token = configJSON.token;
    let header = { 
      token, 
    };
    let httpBody = new FormData(); 

    httpBody.append('data[new_password]', this.state.newPasswordInput);
    httpBody.append('data[confirm_password]', this.state.passwordInput);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccount = async () => {
    this.setState({
      delAccModal: false
    })
    const token = configJSON.token;

    let header = { token };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.exampleAPiMethodDele
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  changePasswordAlert = () => {
    if(this.state.newPasswordInput === "") {
      this.showAlert("Invalid Entry", "Enter Password")
    } else if(this.state.newPasswordInput !== this.state.passwordInput) {
      this.showAlert("Invalid Entry ","Password's don't Match")
    } else {
     
        Alert.alert("Alert" , " Are you sure you want to change the password ? ", [
          { text: "Yes", onPress: () => this.changePassword() },
          {
            text: "Cancel",
            style: "cancel"
          }
        ])
    }
  }

  logoutAlert = () => {
    Alert.alert("Alert" , " Are you sure you want to Logout? ", [
      { text: "Yes", onPress: () => this.logout() },
      {
        text: "No",
        style: "cancel"
      }
    ])
  }

  deleteAccountAlert = () => {
    Alert.alert("Alert" , "Are you sure you want to Delete your Account?", [
      { text: "Yes", onPress: () => this.deleteAccount() },
        {
          text: "No",
          style: "cancel"
      }
    ])  
  }

  closeLogoutModal = () => {
    this.setState({
      logoutModal: false
    })
  }

  closeDelAccModal= () => {
    this.setState({
      delAccModal: false
    })
  }

  closeChangePassModal = () => {
    this.setState({
      changePassModal: false
    })
  }

  openChangePassModal = () => {
    this.setState({
      changePassModal: true
    })
  }

  openLogoutModal = () => {
    this.setState({
      logoutModal: true
    })
  }
  openDelAccModal = () => {
    this.setState({
      delAccModal: true
    })
  }
  
  // Customizable Area End
}
