// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.apiContentType = "application/json";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.address = "Address";
exports.address2 = "Address 2";
exports.city = "City";
exports.state = "State";
exports.country = "Country";
exports.pinCode = "Pin Code";
exports.addressType = "Select Address Type : ";
exports.done = "Done";
exports.addressCreateEndPoint = "bx_block_address/addresses";
exports.updateOrderEndPoint = "bx_block_shiprocket/shopping_cart_orders/";
exports.sendShipRocketEndPoint = "bx_block_shiprocket/create_order/";
exports.getShipRocketEndPoint = "bx_block_shiprocket/shiprocket/order?id=";
exports.trackOrderEndPoint = "bx_block_shiprocket/shiprocket/track_order_by_shipment_id?id=";
exports.getInvoiceEndPoint = "bx_block_shiprocket/generate_invoice/";
exports.getAwbEndPoint = "bx_block_shiprocket/generate_awb/";
exports.getLabelEndPoint = "bx_block_shiprocket/generate_label/";
exports.getRequestPickupEndPoint = "bx_block_shiprocket/request_pickup/";
exports.getManifestEndPoint = "bx_block_shiprocket/generate_manifest/";
exports.postCancelOrderEndPoint = "bx_block_shiprocket/cancel_order/";
exports.postReturnOrderEndPoint = "bx_block_shiprocket/return_order/";
exports.getReturnReasonEndPoint = "bx_block_shiprocket/return_reasons";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "shiprocketintegrate";
exports.labelBodyText = "shiprocketintegrate Body";
exports.getOrdersApiEndPoint = "/bx_block_shopping_cart/orders";
exports.btnExampleTitle = "CLICK ME";
exports.labelCustomerDetails = "Customer Details";
exports.labelCustomerName = "Name: ";
exports.labelCustomerEmail = "Email: ";
exports.labelCustomerMobileNumber = "Mobile Number: ";
exports.labelCustomerOrderDetails = "Order Details";
exports.labelCustomerPrice = "Price: ";
exports.labelCustomerQuantity = "Quantity: ";
exports.labelOrderName = "Name: ";
exports.labelCategoryName = "Category Name: ";
exports.labelSubCategoryName = "Sub Category Name: ";
exports.labelTrackOrder = "Track Order";
exports.labelUpdateOrder = "Update Order";
exports.labelUpdateDetails = "Update Details";
exports.labelUpdateAddress = "Update Address";
exports.labelOrderItem = "Order Item ";
exports.labelShipmentDetails = "Shipment Details";
exports.labelCancelOrder = "Cancel Order";
exports.labelReturnItems = "Return Items";
exports.labelConfirmReturn = "Confirm Return";
exports.labelFillDetails = "Fill Details";
exports.labelViewOrderDetails = "View Order Details";
exports.labelSendShipRocket = "Send to ShipRocket";
exports.labelViewShipRocketDetail = "View ShipRocket Detail";
exports.labelOrderNumber = "Order Number";
exports.labelOrderDate = "Order Date";
exports.labelCustomer = "Customer";
exports.labelStatus = "Status";
exports.labelPickupLocation = "Pickup Location: ";
exports.labelTotalAmount = "Total Amount: ";
exports.labelStatusShipMent = "Status: ";
exports.labelCourier = "Courier: ";
exports.labelShipmentId = "Shipment Id: ";
exports.labelAwbCode = "AWB Code: ";
exports.labelDownloadInvoice = "Download Invoice";
exports.labelGenerateAwb = "Generate AWB";
exports.labelDownloadLabel = "Download Label";
exports.labelRequestPick = "Request Pickup";
exports.labelDownloadManifest = "Download Manifest";
exports.labelOrderTable = "Order Table";
exports.labelCloseModal = "X";
exports.labelTrackingStatus = "Tracking Status";
exports.labelLength = "Length";
exports.labelBreadth = "Breadth";
exports.labelWeight = "Weight";
exports.labelHeight = "Height";
exports.labelMandatoryWarning = "All fields are mandatory!";
exports.labelOrderWarning = "All fields are mandatory and more that 0";
exports.ErrorSomethingWentWrong =
  "Something went wrong, Please try again later.";
exports.ErrorDeviceNotSupports = "Not supported in your device";

exports.getShippingCartOrderAPiEndPoint = "bx_block_shiprocket/shopping_cart_orders";
exports.gettableDataApiContentType = "application/json";
exports.getCustomerAPiMethod = "GET";
exports.getCustomerDataAPiEndPoint = "bx_block_shopping_cart/orders";
exports.getCustomerDataApiContentType = "application/json";
exports.textDate = "Date";
exports.textLocation = "Location";
exports.textHome = "Home";
exports.textWork = "Work";
exports.textOther = "Other";
exports.textLength = "Length";
exports.textBreadth = "Breadth";
exports.textHeight = "Height";
exports.textWeight = "Weight";
exports.textName = "Name";
exports.textEmail = "Email";
exports.textMobile = "Mobile";
exports.textPrice = "Price";
exports.textQuantity = "Quantity";
exports.textCategoryName = "Category Name";
exports.textSubCategoryName = "Sub Category Name";
exports.textSendToShipRocket = "Send to ShipRocket";
exports.textTrackOrder = "Track Order";
exports.textUpdateOrder = "Update Order Address";
exports.textViewShipRocketDetails = "View ShipRocket Details";
exports.textOrderStatusNotFound = "Order status not found";
exports.textGoBackToOrders = "Go back to orders"
exports.updateOrderEndPointWeb = "bx_block_shiprocket/shopping_cart_orders/";
exports.cancelOrderEndPoint = "bx_block_shiprocket/cancel_order/";
exports.getOrdersApiEndPoint = "bx_block_shopping_cart/orders";
exports.buttonCancel = "Cancel";
exports.getDownloadInvoiceAPiMethod = "GET";
exports.getDownloadInvoiceAPiEndPoint = "bx_block_shiprocket/generate_invoice";
exports.getDownloadInvoiceApiContentType = "application/json";
exports.updateOrderTitle = "Update The Details";
exports.textCustomerDetails = "Customer Details";
exports.textOrderDetails = "Order Details";
exports.trackOrderTitle = "Tracking Status";
exports.updateOrderDimenensionText  = "Update order dimension";
exports.textNoOrder = "No order is present !";
exports.downloadInvoiceText = "Download Invoice";
exports.creatOrderErrorMsg = "Create new order details is failed !";
exports.updateOrderErrorMsg = "Update order details is failed !";
exports.commonErrorMsg = "Something went wrong!";
exports.fieldsEmptyWarningMsg = "All fields are mandatory!";
exports.createTokenApiEndPoint = "bx_block_shiprocket/shiprocket/auth_token?region=India"

// Customizable Area End
