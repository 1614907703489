Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "shiprocket";
exports.labelBodyText = "shiprocket Body";
exports.btnExampleTitle = "CLICK ME"; 

exports.btnExampleTitle = "CLICK ME";
exports.lableCreateOrder = "Create Shiprocket Order";

exports.generateAuthToken = "bx_block_shiprocket_mena/shiprockets_mena/generate_auth_code"
exports.generateAccessTokens = "bx_block_shiprocket_mena/shiprockets_mena/generate_token"
exports.getAllOrder = 'bx_block_shiprocket_mena/shiprockets_mena'
exports.canelOrderApiUrl = 'bx_block_shiprocket_mena/shiprockets_mena/cancel_order'
exports.generateAwb = 'bx_block_shiprocket_mena/shiprockets_mena/gene_awb'
exports.generateInvoice = 'bx_block_shiprocket_mena/shiprockets_mena/generate_invoice'
exports.getPickUpAddress = 'bx_block_shiprocket_mena/shiprockets_mena/get_pickup_add'
exports.generateLabelEndPoint = 'bx_block_shiprocket_mena/shiprockets_mena/generate_lable'
exports.generateMenifestEndPoint = 'bx_block_shiprocket_mena/shiprockets_mena/generate_manifest'
exports.trackAwb = 'bx_block_shiprocket_mena/shiprockets_mena/track_awb'
exports.sendShipRocketEndPoint = "bx_block_shiprocket_mena/shiprockets_mena";
exports.getAllCourier = "bx_block_shiprocket_mena/shiprockets_mena/check_serviceability";
exports.generatePickupRequestEndPoint = "bx_block_shiprocket_mena/shiprockets_mena/generate_pickup";


// Customizable Area End