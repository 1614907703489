// Customizable Area Start
import React, { FunctionComponent } from "react";
import { Modal, Box } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const configJSON = require("../config");
import { useStyles } from "./CustomerDetailPopup.web";
import { Typography, Button, Input } from "@builder/component-library"

export type Props = {
  isUpdatePopupOpen: boolean;
  handleUpdatePopupClose: () => void;
  handelInputChange: (event: string, name:string) => void;
  length: string;
  breadth: string;
  height: string;
  weight: string;
  validateOrderUpdate: () => void;
};

const UpdateDetailsPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isUpdatePopupOpen}
      onClose={props.handleUpdatePopupClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.isUpdatePopupOpen}>
        <Box className={classes.paper}>
          <Typography component="h5" style={updateDetailsPopupWebStyle.dimensionHeading}>
            {configJSON.updateOrderDimenensionText}
          </Typography>
          <Box>
            <Typography style={updateDetailsPopupWebStyle.inputLable}>
              {configJSON.textLength}
            </Typography>
            <Input
              value={props.length || ""}
              data-test-id="inputLengthTestId"
              onChangeText={(event) => props.handelInputChange(event, "length")}
            />
            <Typography style={updateDetailsPopupWebStyle.inputLable}>
              {configJSON.textBreadth}
            </Typography>
            <Input
              value={props.breadth || ""}
              data-test-id="breadthTestId"
              onChangeText={(event) => props.handelInputChange(event, "breadth")}
            />
            <Typography style={updateDetailsPopupWebStyle.inputLable}>
              {configJSON.textHeight}
            </Typography>
            <Input
              data-test-id="heightTestId"
              value={props.height || ""}
              onChangeText={(event) => props.handelInputChange(event, "height")}
            />
            <Typography style={updateDetailsPopupWebStyle.inputLable}>
              {configJSON.textWeight}
            </Typography>
            <Input
              data-test-id="weightTestId"
              value={props.weight || ""}
              onChangeText={(event) => props.handelInputChange(event, "weight")}
            />
          </Box>
          <Button
            onPress={props.validateOrderUpdate}
            style={updateDetailsPopupWebStyle.buttonWrapper}
            data-test-id="updateDimensionButton"
            text={configJSON.done}
            textStyle={updateDetailsPopupWebStyle.doneButton}
            >
          </Button>
          <Button
            onPress={props.handleUpdatePopupClose}
            style={updateDetailsPopupWebStyle.buttonWrapper}
            data-test-id="closeDimensionButton"
            text={configJSON.buttonCancel}
            textStyle={updateDetailsPopupWebStyle.cancelButton}
            >
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateDetailsPopup;


const updateDetailsPopupWebStyle = {
  buttonWrapper: {
    backgroundColor: "#fff",
    borderRadius: 10,
    color: "#ffffff",
    width: "100%",
    borderBottomColor: "#2297f8",
    border:"1px solid #2297f8",
    marginTop: 10,
  },
  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },
  doneButton: {
    color: "green",
    marginTop: "5px",
  },
  cancelButton: {
    color: "red",
    marginTop: "5px",
  },
};
// Customizable Area End
