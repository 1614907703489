// Customizable Area Start
import React, { FunctionComponent } from "react";
import {
  Modal,
  Box,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "./CustomerDetailPopup.web";
import { ICustomerDataTwoWeb, OrderDataAttributesOrderItemWeb } from "../types";
const configJSON = require("../config");
import { Typography, Button } from "@builder/component-library"

export type Props = {
  shipRocketModalData?: {
    pickup_location?: string;
    net_total?: string;
    shipments?: {
      courier?: string;
      id?: string;
      awb?: string;
    };
    status?: string;
    awb_data: {
      awb: string;
    };
  } | null;
  data:
    | ICustomerDataTwoWeb
    | {
        id: string;
        type?: string;
        attributes: {
          order_items: {
            data: [];
          };
          ship_rocket_order_id: string;
          ship_rocket_awb_code: string;
          status: string;
          customer: {
            data: {
              attributes: {
                name: string;
                first_name: string;
                last_name: string;
                email: string;
                full_phone_number: string;
                ship_rocket_order_id: string;
                status: string;
                sub_category: {
                  name: string;
                };
                category: {
                  attributes: {
                    name: string;
                  };
                };
              };
            };
          };
        };
      };
  showShipmentDataModal: boolean;
  handleClose: () => void;
  handleInvoice: (id: string) => void;
  generateAwb: (orderId: string) => void;
  downloadLabel: (orderId: string) => void;
  awbError: string;
  labelDownloadError: string;
  pickUpRequestError: string;
  pickupRequest: (orderId: string) => void;
  downloadManifest: (orderId: string) => void;
  manifestError: string;
  OrderCancel: (orderId: string) => void;
  viewOrderDetails: (
    orderData: OrderDataAttributesOrderItemWeb[],
    status: string,
    orderId: string,
  ) => void;
  isLoadingCancelOrder: boolean;
  isLoadingInvoice: boolean;
  isLoadingAwb: boolean;
};

const ShipmentDetailsPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.showShipmentDataModal}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ width: "35%", margin: "auto" }}>
      <Fade in={props.showShipmentDataModal}>
        <Box className={classes.paper}>
          <Typography variant="xl">Shipment Details</Typography>
          <Box>
            <Typography>
              Pickup Location: {props.shipRocketModalData?.pickup_location ? props.shipRocketModalData?.pickup_location : ""}
            </Typography>
            <Typography>
              Total Amount: {props.shipRocketModalData?.net_total ? props.shipRocketModalData?.net_total : ""}
            </Typography>
            <Typography>Status: {props.shipRocketModalData?.status ? props.shipRocketModalData?.status : ""}</Typography>
            <Typography>
              Courier: {props.shipRocketModalData?.shipments?.courier ? props.shipRocketModalData?.shipments?.courier : ""}
            </Typography>
            <Typography>
              Shipment id: {props.shipRocketModalData?.shipments?.id ? props.shipRocketModalData?.shipments?.id : ""}
            </Typography>
            <Typography>
              AWB code: {props.shipRocketModalData?.shipments?.awb ? props.shipRocketModalData?.shipments?.awb : ""}
            </Typography>
          </Box>
          <Box style={shipMentWebStyle.buttonWrapperMain}>
            <Button
              text={configJSON.labelViewOrderDetails}
              data-test-id="orderViewDetails"
              style={shipMentWebStyle.buttonWrapper}
              onPress={() =>
                props.viewOrderDetails(
                  props.data.attributes.order_items.data,
                  props.data.attributes.status,
                  props.data.id,
                )
              }>
            </Button>
            <Button
              loading={props.isLoadingInvoice}
              text={configJSON.downloadInvoiceText}
              disabled={props.data.attributes.ship_rocket_order_id ? false : true}
              style={
                props.data.attributes.ship_rocket_order_id
                  ? shipMentWebStyle.buttonWrapper
                  : shipMentWebStyle.disableButtonWrapper
              }
              onPress={() => props.handleInvoice(props.data.id)}
              data-test-id="handleInvoiceButton">
            </Button>
            {!props.shipRocketModalData?.awb_data.awb && (
              <Button
                text="Generate AWB"
                loading={props.isLoadingAwb}
                data-test-id="generateAwbButton"
                style={shipMentWebStyle.buttonWrapper}
                onPress={() => props.generateAwb(props.data.id)}>
              </Button>
            )}
            <Typography style={shipMentWebStyle.errorText}>
              {props.awbError}
            </Typography>
            {props?.data?.attributes?.ship_rocket_order_id &&
              props?.shipRocketModalData?.awb_data.awb && (
                <Button
                  text="Download Label"
                  data-test-id="downloadLabel"
                  style={shipMentWebStyle.buttonWrapper}
                  onPress={() => props.downloadLabel(props?.data?.id)}>
                </Button>
              )}
            <Typography style={shipMentWebStyle.errorText}>
              {props.labelDownloadError}
            </Typography>
            {props?.data?.attributes?.ship_rocket_order_id &&
              props.shipRocketModalData?.awb_data.awb && (
                <>
                  <Button
                    text="Request Pickup"
                    data-test-id="pickupRequestButton"
                    style={shipMentWebStyle.buttonWrapper}
                    onPress={() => props.pickupRequest(props.data.id)}>
                  </Button>
                  <Typography style={shipMentWebStyle.errorText}>
                    {props?.pickUpRequestError}
                  </Typography>
                </>
              )}
            {props.data.attributes?.ship_rocket_order_id &&
              props.shipRocketModalData?.awb_data.awb && (
                <>
                  <Button
                    text={configJSON.labelDownloadManifest}
                    data-test-id="downloadManifestButton"
                    onPress={() => props.downloadManifest(props?.data?.id)}
                    style={shipMentWebStyle.buttonWrapper}>
                  </Button>
                  <Typography style={shipMentWebStyle.errorText}>
                    {props.manifestError}
                  </Typography>
                </>
              )}
            {props.data.attributes.status.toUpperCase() !== "DELIVERED" ? (
              <Button
                text="Cancel Order"
                loading={props.isLoadingCancelOrder}
                style={shipMentWebStyle.buttonWrapper}
                onPress={() => props.OrderCancel(props?.data?.id)}>
              </Button>
            ) : null}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ShipmentDetailsPopup;

const shipMentWebStyle = {
  buttonWrapperMain: {
    display:"flex",
    flexDirection: "column" as "column",
    gap: "8px"
  },
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: 10,
  },

  disableButtonWrapper: {
    backgroundColor: "#808080",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: 10,
  },

  errorText: {
    color: "red",
  },

  titleText: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    marginBottom: "10px",
  },
};

// Customizable Area End
